<template>
  <div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-form
        label-position="top"
        class="tw-w-full"
        :model="add[type]"
        :rules="rules[type]"
        ref="rules"
      >
        <div
          class="tw-flex tw-flex-col tw-flex-wrap tw-gap-x-4 tw-gap-y-6 tw-pt-3 tw-border-r tw-border-gray-100"
        >
          <div>
            <el-form-item
              v-if="!add.referee.memberId"
              prop="teamId"
              :label="`${type} Team Appointment`"
            >
              <el-select
                v-model="add[type].teamId"
                filterable
                :placeholder="`Assign a team to ${type}`"
                style="width: 100%; max-width: calc(400px + 1rem)"
              >
                <el-option
                  v-for="team in availableTeams"
                  :key="team._id"
                  :label="fullTeamName(team)"
                  :value="team._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div
            class="tw-flex tw-flex-wrap tw-gap-x-4 tw-gap-y-6 tw-pt-3 tw-items-end tw-border-r tw-border-gray-100"
          >
            <el-form-item v-if="!add.referee.teamId" prop="memberId" :label="`${type} Appointment`">
              <el-select
                v-model="add[type].memberId"
                filterable
                :placeholder="`Assign a ${type}`"
                style="width: 200px"
              >
                <el-option
                  v-for="ref in availableRefs"
                  :key="ref._id"
                  :label="fullname(ref)"
                  :value="ref._id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="position" label="Position">
              <el-select
                v-model="add[type].position"
                placeholder="Position"
                value-key="name"
                filterable
                style="width: 200px"
              >
                <el-option
                  v-for="(item, index) in refPositions"
                  :key="index"
                  :label="item.name"
                  :value="item.number"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-button style="width: 200px" type="success" @click="handleAdd">
              Add {{ type }}
            </el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <data-tables
        v-if="newAssignments[type].length > 0"
        :data="sortedNewAssignments"
        :table-props="tableProps"
        :page-size="pageSize"
        @row-click="removeFromAssignmentList"
        id="refereelist"
        class="data-table"
      >
        <el-table-column label="Name">
          <template slot-scope="scope">
            {{ fullname(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="position" label="Position"> </el-table-column>
        <el-table-column align="center" prop="" label="Actions">
          <template>
            <i class="el-icon-delete remove-icon"></i>
          </template>
        </el-table-column>
      </data-tables>
    </div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="12">
        <el-button type="success" @click="submitMatchAssignments()">Save</el-button>
        <el-button type="default" @click="cancelForm()">Cancel</el-button>
      </el-col>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { errormsg } from "../../utils/constants";

export default {
  name: "Assignments",
  props: {
    association: Number,
    competitionid: Number,
    match: Object,
    matchid: Number,
    type: String,
    teams: Array,
  },
  data() {
    return {
      add: {
        referee: {
          memberId: undefined,
          teamId: undefined,
          position: undefined,
        },
      },
      assignmentTeam: [],
      assignmentTeamIds: [],
      assignmentTeamsExp: [],
      assignmentType: {
        referee: "Referee",
      },
      associationTeams: [],
      newAssignments: {
        referee: [],
      },
      pageSize: 100,
      refPositions: [
        {
          name: "Referee",
          number: 2,
        },
        {
          name: "Referee Coach",
          number: 1,
        },
      ],
      rules: {
        referee: {
          teamId: {
            required: false,
            message: errormsg.select_option,
            trigger: "change",
          },
          memberId: {
            required: false,
            message: errormsg.select_option,
            trigger: "change",
          },
          position: {
            required: true,
            message: errormsg.select_option,
            trigger: "change",
          },
        },
      },
      savedAssignments: [],
      tableProps: {
        border: true,
      },
    };
  },
  async created() {
    try {
      const { association, competitionid, matchid, type } = this;
      this.$store.commit("root/loading", true);
      const [teamIdsRes, matchAssignmentRes, matchAssignmentTeamsRes, assocIdRes] =
        await Promise.all([
          this.$http.get(`/nrl/api/v1/admin/competition/${competitionid}/${type}`),
          this.$http.get(`/nrl/api/v1/admin/matches/${matchid}/assignment-status/${type}`),
          this.$http.get(`/nrl/api/v1/admin/${type}/teams/competition/${competitionid}`),
          this.$http.get(`/nrl/api/v1/admin/competitions/teams/association/${association}`),
        ]);
      const { assignedTeamIds } = teamIdsRes.data.data && teamIdsRes.data.data.length > 0 && teamIdsRes.data.data[0];
      this.assignmentTeamIds = assignedTeamIds;

      const d = matchAssignmentRes.data.data;
      const { matchAssignments } = d;

      if (matchAssignments.length) {
        const assigned = matchAssignments.map((v) => {
          const { memberId, teamId, role } = v;
          return { memberId, teamId, position: role };
        });

        this.assigned = assigned;
      }

      this.assignmentTeamsExp =
        matchAssignmentTeamsRes.data && matchAssignmentTeamsRes.data.data
          ? matchAssignmentTeamsRes.data.data
          : [];

      this.associationTeams = assocIdRes.data && assocIdRes.data.data ? assocIdRes.data.data : [];

      this.$store.commit("root/loading", false);
    } catch (error) {
      this.$store.commit("root/loading", false);
    }
  },
  methods: {
    cancelForm() {
      this.$router.go(-1);
    },
    fullname(p) {
      return p.profile && p.profile.firstName && p.profile.lastName
        ? `${p.profile.firstName} ${p.profile.lastName}${p.teamName ? " - " + p.teamName : ""}`
        : p.profile && p.profile.name && p.profile.competitionName
        ? `${p.profile.name} - ${p.profile.competitionName}`
        : p.profile && p.profile.name && !p.profile.competitionName
        ? p.profile.name
        : p.memberId
        ? `Member: ${p.memberId}`
        : `Team: ${p.teamId}`;
    },
    fullTeamName(t) {
      return t.name && t.competitionName
        ? `${t.name} - ${t.competitionName}`
        : !t.competitionName
        ? t.name
        : `Team: ${t._id}`;
    },
    async handleAdd() {
      try {
        switch (this.type) {
          case "referee":
            await new Promise((resolve, reject) => {
              this.$refs.rules.validate((valid) => (valid ? resolve() : reject()));
            });
            const stdRefs = this.newAssignments.referee.filter((r) => r.position === "Referee");
            if (stdRefs.length > 2 && this.add[this.type].position === 2)
              throw {
                max: ` Maximum number of referees (${stdRefs.length}) has been reached.`,
              };
            const { memberId, teamId } = this.add[this.type];
            let assignmentProf;
            let teamName;
            let teamIdOther;
            if (memberId) {
              const { profile } = this.assignmentTeam.find((mem) => {
                return mem._id === memberId;
              });
              assignmentProf = profile;
            }
            if (teamId && !memberId) {
              assignmentProf = this.availableTeams.find((t) => t._id === teamId);
            } else {
              const mem = this.assignmentTeam.find((m) => m._id === memberId);
              teamName = mem.teamName;
              teamIdOther = this.assignmentTeamsExp.find((team) => team.name === teamName)._id;
            }
            let posStr;
            if (this.isRefereeAssignment)
              posStr = this.refPositions.find((ref) => ref.number === this.add[this.type].position);
            const assignment = {
              profile: assignmentProf,
              teamName,
            };
            if (memberId) assignment.memberId = memberId;
            assignment.teamId = teamId || teamIdOther;
            if (this.isRefereeAssignment) {
              assignment.position = posStr.name;
            }
            this.newAssignments[this.type].push(assignment);
            this.$set(this.add[this.type], "memberId", undefined);
            if (this.isRefereeAssignment) this.$set(this.add[this.type], "teamId", undefined);
            if (this.isRefereeAssignment) this.$set(this.add[this.type], "position", undefined);
            break;
        }
      } catch (error) {
        console.error(error);
        let errMsg = `Could not add ${this.type}.`;
        if (error.max) errMsg += error.max;
        this.$customError(errMsg);
      }
    },
    removeFromAssignmentList(row) {
      this.$confirm(
        `Are you sure you want to remove this member's assignment from the ${
          this.assignmentType[this.type]
        } list`,
        `Remove ${this.assignmentType[this.type]}`,
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          this.newAssignments[this.type].splice(
            this.newAssignments[this.type].findIndex(
              (mem) =>
                mem.memberId === row.memberId &&
                mem.teamId === row.teamId &&
                row.position === mem.position
            ),
            1
          );
        })
        .catch(() => {});
    },
    async submitMatchAssignments(rerender = true) {
      this.$confirm(
        `Submitting this list will override existing ${this.type} match assignments. Do you want to continue?`,
        `Submit ${this.assignmentType[this.type]} Appointments`,
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(async () => {
          this.$store.commit("root/loading", true);
          const { savedAssignments } = this; // member/s assigned to match prior to viewing match
          const updatedAssignments = this.newAssignments[this.type].reverse(); // current member/s selected in form to update

          const newAssignments = updatedAssignments.filter((mem) => {
            const { memberId, teamId, position } = mem;
            return !savedAssignments.find((am) => {
              return am.teamId === teamId && am.memberId === memberId && am.position === position;
            });
          });
          const unassignments = savedAssignments.filter((mem) => {
            const { memberId, teamId, position } = mem;
            return !updatedAssignments.find((am) => {
              return am.teamId === teamId && am.memberId === memberId && am.position === position;
            });
          });
          const assignmentSubmissions = [];
          unassignments.forEach((na) => {
            const insert = {
              type: this.type,
              comp: {
                id: this.competitionid,
                name: this.match.competition.name,
              },
              match: {
                id: this.match._id,
                home: {
                  id: this.match.homeTeam._id,
                  name: this.match.homeTeam.name,
                },
                away: {
                  id: this.match.awayTeam._id,
                  name: this.match.awayTeam.name,
                },
                venue: {
                  _id: this.match.venue._id,
                  name: this.match.venue.name,
                },
                dateTime: this.match.dateTime,
              },
            };
            const { memberId, teamId } = na;
            if (!teamId && !memberId) throw new Error("Bad data");
            if (memberId) insert.memberId = memberId;
            if (teamId) insert.teamId = teamId;
            if (this.isRefereeAssignment) {
              insert.status = "ref_unassigned";
              insert.role = na.position || "Referee";
            }
            assignmentSubmissions.push(insert);
          });
          newAssignments.forEach((na) => {
            const insert = {
              type: this.type,
              comp: {
                id: this.competitionid,
                name: this.match.competition.name,
              },
              match: {
                id: this.match._id,
                home: {
                  id: this.match.homeTeam._id,
                  name: this.match.homeTeam.name,
                },
                away: {
                  id: this.match.awayTeam._id,
                  name: this.match.awayTeam.name,
                },
                venue: {
                  _id: this.match.venue._id,
                  name: this.match.venue.name,
                },
                dateTime: this.match.dateTime,
              },
            };
            const { memberId, teamId } = na;

            if (!teamId && !memberId) throw new Error("Bad data");
            if (memberId) insert.memberId = memberId;
            if (teamId) insert.teamId = teamId;

            if (this.isRefereeAssignment) {
              insert.status = "ref_assigned";
              insert.role = na.position || "Referee";
            }
            assignmentSubmissions.push(insert);
          });

          if (!assignmentSubmissions.length) throw new Error("No changes found to save");
          await this.$http.post("/nrl/api/v1/admin/bulk-match-assignments", {
            assignmentSubmissions,
          });
          this.$store.commit("root/loading", false);
          if (rerender) this.$router.go(-1); // either parent component is match list or match-score bulk editing
          this.$customSuccess(`${this.assignmentType[this.type]}s Successfully Assigned`);
          return true;
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e);
        });
    },
  },
  computed: {
    availableRefs() {
      // copy of all unique ids
      const assignmentIdArr = this.assignmentTeam
        .map((mem) => mem._id)
        .filter((value, index, self) => self.indexOf(value) === index);
      return this.assignmentTeam
        .filter((mem) => {
          if (!assignmentIdArr.includes(mem._id)) return false; // prevents duplicate refs added
          const index = assignmentIdArr.indexOf(mem._id);
          if (index > -1) {
            assignmentIdArr.splice(index, 1);
          }

          return !this.newAssignments[this.type].find(({ memberId }) => {
            return mem._id === memberId;
          });
        })
        .sort((a, b) => {
          const teamA = a.teamName.toUpperCase(); // ignore upper and lowercase
          const teamB = b.teamName.toUpperCase(); // ignore upper and lowercase
          const nameA = a.profile.firstName.toUpperCase(); // ignore upper and lowercase
          const nameB = b.profile.firstName.toUpperCase(); // ignore upper and lowercase
          if (teamA < teamB) return -1;
          if (teamA > teamB) return 1;
          if (teamA === teamB) {
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
          }

          // names must be equal
          return 0;
        });
    },
    availableTeams() {
      const teamOptions = this.associationTeams.length ? this.associationTeams : this.teams;
      const assignmentIdArr = teamOptions
        .filter((team) => {
          const { _id, memberType } = team;
          return (
            !this.newAssignments[this.type].find(({ teamId }) => {
              return _id === teamId;
            }) && memberType !== "referee"
          );
        })
        .sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          const compNameA = a.competitionName ? a.competitionName.toUpperCase() : "";
          const compNameB = b.competitionName ? b.competitionName.toUpperCase() : "";
          if (compNameA < compNameB) {
            return -1;
          }
          if (compNameA > compNameB) {
            return 1;
          }

          if (compNameA === compNameB) {
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          }
        });
      return assignmentIdArr;
    },
    isRefereeAssignment() {
      return this.type === "referee";
    },
    sortedNewAssignments() {
      const { newAssignments, type } = this;
      return newAssignments[type].sort((a, b) => {
        if (a.position > b.position) return -1;
        if (a.position < b.position) return 1;
        if (a.position === b.position) {
          const orderA = a.createdAt;
          const orderB = b.createdAt;
          if (orderA > orderB) return -1;
          if (orderA < orderB) return 1;
          if (orderA === orderB) return 0;
        }
      });
    },
  },
  watch: {
    async assignmentTeamIds(ids) {
      let memArr = [];
      const assignmentArr = [];
      if (ids) {
        ids.forEach(async (id) => {
          assignmentArr.push(
            this.$http.get(`/nrl/api/v1/admin/teamlists/players/${id}`),
            this.$http.get(`/nrl/api/v1/admin/teamlists/nonplayers/${id}`)
          );
        });
        const values = await Promise.all(assignmentArr);
        values.forEach((res) => {
          let teamName;
          if (res.config.url) {
            const urlBreakdown = res.config.url.split("/");
            const teamId = parseInt(urlBreakdown[urlBreakdown.length - 1]);
            const refsTeam = this.assignmentTeamsExp.find((team) => team._id === teamId);
            teamName = refsTeam.name;
          }
          if (res.data && res.data.data) {
            res.data.data.forEach((ref) => {
              if (teamName) ref.teamName = teamName;
              memArr.push(ref);
            });
          }
        });
      }

      this.assignmentTeam = memArr;

      const assignmentRes = await this.$http.get(
        `/nrl/api/v1/admin/matches/${this.matchid}/assignment-status/${this.type}`
      );
      const d = assignmentRes.data.data;
      const { matchAssignments } = d;

      if (matchAssignments.length) {
        const newArr = matchAssignments.map((ma) => {
          const { memberId, teamId, createdAt } = ma;
          const object = {
            memberId,
            teamId,
            createdAt,
          };
          if (this.isRefereeAssignment) object.position = ma.role;

          return object;
        });

        this.savedAssignments = newArr;
      }

      const { savedAssignments } = this;
      if (savedAssignments && savedAssignments.length) {
        savedAssignments.forEach((mem) => {
          const { memberId, teamId, createdAt } = mem;
          let profArr;
          if (memberId) {
            profArr = this.assignmentTeam.find((p) => {
              return p._id === memberId;
            });
          }
          if (teamId && !memberId) {
            profArr = this.availableTeams.find((t) => t._id === teamId);
          }

          const profile =
            profArr && profArr.profile ? profArr.profile : profArr.name ? profArr : null;
          const position = mem && mem.position ? mem.position : null;
          const previousAssignment = {
            createdAt,
            profile,
          };
          if (memberId) previousAssignment.memberId = memberId;
          if (teamId) previousAssignment.teamId = teamId;
          if (this.isRefereeAssignment) {
            previousAssignment.position = position;
            const mem = this.assignmentTeam.find((m) => m._id === memberId);
            if (mem) previousAssignment.teamName = mem.teamName;
          }
          this.newAssignments[this.type].push(previousAssignment);
        });
      }
    },
  },
};
</script>
<style scoped>
.notify-assignments:hover {
  border: solid 3px rgb(127 29 29) !important;
}
</style>
