<template>
  <div>
    <el-row class="go-back">
      <el-button @click="cancelForm" class="go-back-button" icon="el-icon-arrow-left">
        Go Back
      </el-button>
    </el-row>
    <el-tabs type="card">
      <el-tab-pane label="Member Details">
        <el-row>
          <el-col :span="24" class="avatar-box entity-card">
            <vue-upload-component
              v-if="this.$route.params.type !== 'insert'"
              ref="headshotupload"
              inputId="headshotUpload"
              :drop="true"
              v-model="uploads"
              :extensions="extensions"
              :accept="accept"
              :post-action="headshotUploadURL"
              :headers="uploadHeaders"
              :data="memberData"
              :multiple="true"
              @input-file="inputFile"
              @input-filter="inputFilter"
            >
              <div class="avatar-wrap">
                <div
                  :style="{ 'background-image': `url(${currentHeadshot})` }"
                  alt=""
                  class="avatar"
                ></div>
                <div class="upload-logo">
                  <svg
                    width="18px"
                    height="16px"
                    viewBox="0 0 18 16"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        id="Artboard"
                        transform="translate(-47.000000, -88.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <path
                          d="M48.9756098,103.852104 C47.8887171,103.852104 47,102.913299 47,101.765148 L47,92.4897855 C47,91.3416348 47.8887171,90.402829 48.9756098,90.402829 L51.7538,90.402829 L52.5220927,88.3810806 C52.5867013,88.2038545 52.74706,88.0861414 52.9268293,88.0839791 L59.0731707,88.0839791 C59.25294,88.0861414 59.4132987,88.2038545 59.4779073,88.3810806 L60.2462,90.402829 L63.0243902,90.402829 C64.1112829,90.402829 65,91.3416348 65,92.4897855 L65,101.765148 C65,102.913299 64.1112829,103.852104 63.0243902,103.852104 L48.9756098,103.852104 Z M48.9756098,102.924568 L63.0243902,102.924568 C63.6400341,102.924568 64.1219512,102.41549 64.1219512,101.765148 L64.1219512,92.4897855 C64.1219512,91.8394435 63.6400341,91.3303652 63.0243902,91.3303652 L59.9512195,91.3303652 C59.7731474,91.3293876 59.6132696,91.2148899 59.5464829,91.0405101 L58.7781902,89.0115154 L53.2218098,89.0115154 L52.4535171,91.0405101 C52.3867304,91.2148899 52.2268526,91.3293876 52.0487805,91.3303652 L48.9756098,91.3303652 C48.3599659,91.3303652 47.8780488,91.8394435 47.8780488,92.4897855 L47.8780488,101.765148 C47.8780488,102.41549 48.3599659,102.924568 48.9756098,102.924568 Z M56,101.30138 C53.8230317,101.30138 52.0487805,99.4271536 52.0487805,97.1274667 C52.0487805,94.8277565 53.8230317,92.9535536 56,92.9535536 C58.1770122,92.9535536 59.9512195,94.8277797 59.9512195,97.1274667 C59.9512195,99.4271536 58.1770122,101.30138 56,101.30138 Z M56,100.373843 C57.7024707,100.373843 59.0731707,98.9258667 59.0731707,97.1274667 C59.0731707,95.3290435 57.7024707,93.8810899 56,93.8810899 C54.2975732,93.8810899 52.9268293,95.3290435 52.9268293,97.1274667 C52.9268293,98.9258667 54.2975732,100.373843 56,100.373843 Z"
                          id="Shape"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </vue-upload-component>

            <div
              v-if="currentMember && currentMember.avatar"
              style="text-align: center; padding-left: 6rem; margin-top: -10px"
            >
              <el-dropdown @command="onCommandDropdown">
                <span style="" class="el-dropdown-link">
                  <i class="el-icon-remove-outline el-icon--right" style="font-size: 1.6rem"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="replace-image">Replace image</el-dropdown-item>
                  <el-dropdown-item command="remove-image">Remove image</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-form
            label-position="top"
            :model="currentMember"
            :rules="rules"
            ref="currentMember"
            class="login-form"
          >
            <!-- START BASIC DETAILS -->
            <el-collapse v-model="activeNames">
              <el-collapse-item title="Member Eligibility" name="" v-if="!isTfaAdmin">
                <el-row :gutter="10">
                  <el-col :span="16">
                    <el-form-item prop="registrationEligibility.isNotEligible">
                      <el-checkbox
                        v-model="currentMember.registrationEligibility.isNotEligible"
                        :disabled="!(isNrlAdmin && isSuperAdmin)"
                      >
                        Prevent participant from registering
                      </el-checkbox>
                    </el-form-item>
                  </el-col></el-row
                >
                <el-row :gutter="10">
                  <el-col :span="8" prop="registrationEligibility.reviewDate">
                    <el-form-item label="Review Date">
                      <el-date-picker
                        type="date"
                        placeholder="Select a Date"
                        v-model="currentMember.registrationEligibility.reviewDate"
                        :disabled="!(isNrlAdmin && isSuperAdmin)"
                        value-format="timestamp"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" prop="registrationEligibility.note">
                    <el-form-item label="Note">
                      <el-input
                        placeholder=""
                        v-model="currentMember.registrationEligibility.note"
                        :disabled="!(isNrlAdmin && isSuperAdmin)"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col
                    :span="8"
                    prop="registrationEligibility.adminAlertEmail"
                    v-if="isNrlAdmin && isSuperAdmin"
                  >
                    <el-form-item prop="registrationEligibility.adminAlertEmail" label="Email">
                      <el-input
                        type="email"
                        autocomplete="off"
                        v-model="currentMember.registrationEligibility.adminAlertEmail"
                        placeholder=""
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item title="Personal Details" name="1">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="_id" label="NRL ID">
                      <el-input
                        autocomplete="off"
                        placeholder=""
                        v-model="currentMember._id"
                        :disabled="true"
                        class="nrl-id"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="profile.firstName" label="Legal First Name">
                      <el-input
                        autocomplete="off"
                        placeholder=""
                        v-model="currentMember.profile.firstName"
                        :disabled="!associationAndAbove"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.middleName" label="Legal Middle Name">
                      <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.middleName"
                        placeholder=""
                        :disabled="!associationAndAbove"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      id="memberLastName"
                      prop="profile.lastName"
                      label="Legal Last Name"
                    >
                      <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.lastName"
                        placeholder=""
                        :disabled="!associationAndAbove"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="profile.preferFirstName" label="Preferred First Name">
                      <el-input
                        autocomplete="off"
                        placeholder=""
                        v-model="currentMember.profile.preferFirstName"
                        :disabled="!clubAndAbove"
                        @change="preferredNameChanged"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.preferLastName" label="Preferred Last Name">
                      <el-input
                        autocomplete="off"
                        placeholder=""
                        v-model="currentMember.profile.preferLastName"
                        :disabled="!clubAndAbove"
                        @change="preferredNameChanged"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="16">
                    <el-form-item prop="profile.preferNameStatus">
                      <el-checkbox
                        v-model="currentMember.profile.preferNameStatus"
                        :disabled="!clubAndAbove"
                      >
                        Verify this is the approved preferred name of the participant they wish to
                        display on the match centre. <br />
                        This name change must comply with the games policies.
                      </el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="profile.mobile" label="Mobile Phone">
                      <vue-tel-input
                        v-model="currentMember.profile.mobile"
                        :dropdownOptions="props"
                        :preferredCountries="preferredCountries"
                        :autoFormat="true"
                        :validCharactersOnly="true"
                        mode="international"
                        @input="handlePaste"
                        @validate="validateMobile"
                        :class="!isValidInternationalMobile ? 'red' : ''"
                        :disabled="isViewOnlyAdmin"
                      ></vue-tel-input>
                      <p v-if="!isValidInternationalMobile" class="invalidMobile">
                        Invalid Mobile Number
                      </p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.email" label="Email">
                      <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.email"
                        placeholder=""
                        :disabled="isViewOnlyAdmin"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.maidenName" label="Maiden Name">
                      <el-input
                        autocomplete="off"
                        placeholder=""
                        v-model="currentMember.profile.meta.maidenName"
                        :disabled="!associationAndAbove"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.dob" label="Date of Birth">
                      <el-date-picker
                        type="date"
                        v-model="currentMember.profile.dob"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        placeholder=""
                        :disabled="!associationAndAbove"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.gender" label="Gender">
                      <el-select
                        v-model="currentMember.profile.gender"
                        placeholder=""
                        :disabled="isClubAdmin || isViewOnlyAdmin"
                      >
                        <el-option
                          v-for="item in ['Male', 'Female']"
                          :label="item"
                          :key="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="profile.address.addressLine1" label="Address Line 1">
                      <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.address.addressLine1"
                        placeholder=""
                        :disabled="isViewOnlyAdmin"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.address.addressLine2" label="Address Line 2">
                      <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.address.addressLine2"
                        placeholder=""
                        :disabled="isViewOnlyAdmin"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="profile.address.country" label="Country">
                      <el-select
                        v-model="currentMember.profile.address.country"
                        filterable
                        placeholder=""
                        autocomplete="off"
                        @change="onCountryChange(currentMember.profile.address.country)"
                      >
                        <el-option
                          v-for="item in this.countries"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.address.state" label="State">
                      <el-select
                        v-model="currentMember.profile.address.state"
                        filterable
                        placeholder=""
                        autocomplete="off"
                        :disabled="!currentMember.profile.address.country || isViewOnlyAdmin"
                        @change="onStateChange(currentMember.profile.address.state)"
                      >
                        <el-option
                          v-for="item in this.states"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.address.suburb" label="City/Suburb">
                      <el-select
                        v-model="currentMember.profile.address.suburb"
                        filterable
                        placeholder=""
                        autocomplete="off"
                        :disabled="!currentMember.profile.address.state"
                      >
                        <el-option
                          v-for="item in this.cities"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.address.postcode" label="Postcode">
                      <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.address.postcode"
                        placeholder=""
                        :disabled="isViewOnlyAdmin"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item
                      prop="profile.meta.parentOrGuardian1.name"
                      label="Parent / Guardian Name"
                    >
                      <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.meta.parentOrGuardian1.name"
                        placeholder=""
                        :disabled="isViewOnlyAdmin"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      prop="profile.meta.parentOrGuardian1.email"
                      label="Parent / Guardian Email"
                    >
                      <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.meta.parentOrGuardian1.email"
                        placeholder=""
                        :disabled="isViewOnlyAdmin"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      prop="profile.meta.parentOrGuardian1.mobile"
                      label="Parent / Guardian Mobile"
                    >
                      <vue-tel-input
                        v-model="currentMember.profile.meta.parentOrGuardian1.mobile"
                        :dropdownOptions="props"
                        :preferredCountries="preferredCountries"
                        :autoFormat="true"
                        :validCharactersOnly="true"
                        mode="international"
                        @input="handleParentPaste"
                        @validate="validateParentMobile"
                        :class="!isValidParentInternationalMobile ? 'red' : ''"
                        :disabled="isViewOnlyAdmin"
                      ></vue-tel-input>
                      <p v-if="!isValidParentInternationalMobile" class="red">
                        Invalid Mobile Number
                      </p>
                    </el-form-item>
                    <!-- <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.meta.parentOrGuardian1.mobile"
                        placeholder=""
                      /> -->
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="profile.isPrivate" label="Private Profile">
                      <el-select
                        v-model="currentMember.profile.isPrivate"
                        placeholder="Private Profile"
                        filterable
                        :disabled="isViewOnlyAdmin"
                      >
                        <el-option
                          v-for="item in [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                          ]"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item title="Emergency Contact" name="2">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="profile.emergency.name" label="Emergency Contact Name">
                      <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.emergency.name"
                        placeholder=""
                        :disabled="isViewOnlyAdmin"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.emergency.mobile" label="Emergency Contact Number">
                      <vue-tel-input
                        v-model="currentMember.profile.emergency.mobile"
                        :dropdownOptions="props"
                        :preferredCountries="preferredCountries"
                        :autoFormat="true"
                        :validCharactersOnly="true"
                        mode="international"
                        @input="handleEmergencyPaste"
                        @validate="validateEmergencyMobile"
                        :class="!isValidEmergencyInternationalMobile ? 'red' : ''"
                        :disabled="isViewOnlyAdmin"
                      ></vue-tel-input>
                      <p v-if="!isValidEmergencyInternationalMobile" class="red">
                        Invalid Mobile Number
                      </p>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item title="Health Details" name="3">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.height" label="Height">
                      <el-input
                        type="number"
                        autocomplete="off"
                        v-model.number="currentMember.profile.meta.height"
                        placeholder=""
                        :disabled="isViewOnlyAdmin"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.weight" label="Weight">
                      <el-input
                        type="number"
                        autocomplete="off"
                        v-model.number="currentMember.profile.meta.weight"
                        placeholder=""
                        :disabled="isViewOnlyAdmin"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.medical.hasAllergies" label="Allergies">
                      <el-select
                        v-model="currentMember.profile.meta.medical.hasAllergies"
                        placeholder=""
                        filterable
                        :disabled="isViewOnlyAdmin"
                      >
                        <el-option
                          v-for="item in [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                          ]"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item
                      prop="profile.meta.medical.allowedTreatment"
                      label="Medical Treatment"
                    >
                      <el-select
                        v-model="currentMember.profile.meta.medical.allowedTreatment"
                        placeholder=""
                        filterable
                        :disabled="isViewOnlyAdmin"
                      >
                        <el-option
                          v-for="item in [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                          ]"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.medical.hasMedical" label="Medical Conditions">
                      <el-select
                        v-model="currentMember.profile.meta.medical.hasMedical"
                        placeholder=""
                        filterable
                        :disabled="isViewOnlyAdmin"
                      >
                        <el-option
                          v-for="item in [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                          ]"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.medical.notes" label="Medical Notes">
                      <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.meta.medical.notes"
                        placeholder="Medical Notes"
                        :disabled="isViewOnlyAdmin"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item
                      prop="profile.meta.disability.hasDisability"
                      label="Has a Disability"
                    >
                      <el-select
                        v-model="currentMember.profile.meta.disability.hasDisability"
                        placeholder=""
                        filterable
                        :disabled="isViewOnlyAdmin"
                      >
                        <el-option
                          v-for="item in [
                            { value: 'no', label: 'No' },
                            { value: 'yes', label: 'Yes' },
                            { value: 'prefer-not-answer', label: 'Prefer not to say' },
                          ]"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      prop="profile.meta.disability.disabilityType"
                      label="Disability Type"
                    >
                      <el-select
                        v-model="currentMember.profile.meta.disability.disabilityType"
                        placeholder=""
                        filterable
                        :disabled="
                          currentMember.profile.meta.disability.hasDisability === 'no' ||
                          currentMember.profile.meta.disability.hasDisability ===
                            'prefer-not-answer' ||
                          isViewOnlyAdmin
                        "
                      >
                        <el-option
                          v-for="item in disabilityTypes"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item title="Accreditations & WWC Information" name="4">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.imcId" label="IMC ID">
                      <el-input
                        type="number"
                        autocomplete="off"
                        v-model.number="currentMember.profile.meta.imcId"
                        placeholder=""
                        :disabled="!isSuperAdmin"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.wwc.number" label="WWC Number">
                      <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.meta.wwc.number"
                        placeholder=""
                        :disabled="isViewOnlyAdmin"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.wwc.issuer" label="WWC State Issuer">
                      <el-select v-model="currentMember.profile.meta.wwc.issuer" placeholder="">
                        <el-option
                          v-for="{ value, label } in states"
                          :label="label"
                          :key="value"
                          :value="value"
                          :disabled="isViewOnlyAdmin"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.wwc.status" label="WWC Status">
                      <el-select
                        v-model="currentMember.profile.meta.wwc.status"
                        placeholder=""
                        :disabled="isViewOnlyAdmin"
                      >
                        <el-option
                          v-for="{ type, name } in wwcStatus"
                          :label="name"
                          :key="type"
                          :value="type"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.wwc.expiry" label="WWC Expiry">
                      <el-date-picker
                        type="date"
                        v-model="currentMember.profile.meta.wwc.expiry"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        placeholder=""
                        :disabled="isViewOnlyAdmin"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item title="Other" name="5">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.contractEntered" label="Contract Entered">
                      <el-input
                        type="text"
                        autocomplete="off"
                        v-model="currentMember.profile.meta.contractEntered"
                        placeholder=""
                        :disabled="!isAssociationAdmin || isTfaAdmin"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="profile.meta.developmentFee" label="Development Fee Player">
                      <el-select
                        v-model="currentMember.profile.meta.developmentFee"
                        placeholder=""
                        filterable
                        :disabled="!isStateAdmin || isTfaAdmin"
                      >
                        <el-option
                          v-for="item in [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                          ]"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="defaulter" label="Defaulter">
                      <el-select
                        v-model="currentMember.defaulter"
                        placeholder=""
                        filterable
                        :disabled="isTfaAdmin || isViewOnlyAdmin"
                      >
                        <el-option
                          v-for="item in [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                          ]"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item prop="" label="Deceased">
                      <el-select
                        v-model="currentMember.profile.meta.deceased"
                        placeholder=""
                        filterable
                        :disabled="isViewOnlyAdmin"
                      >
                        <el-option
                          v-for="item in [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                          ]"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item title="Member Availability" name="6" v-if="associationAndAbove">
                <el-row :gutter="10">
                  <el-col :span="8" v-if="associationAndAbove">
                    <el-form-item prop="available.is" label="Player Availability">
                      <el-select
                        v-model="currentMember.available.is"
                        @change="setAvailableDate"
                        placeholder="Player Availability"
                        filterable
                      >
                        <el-option
                          v-for="item in [
                            { value: false, label: 'Unavailable' },
                            { value: true, label: 'Available' },
                          ]"
                          :label="item.label"
                          :key="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" prop="available.endDate" v-if="associationAndAbove">
                    <el-form-item label="Available Date">
                      <el-date-picker
                        type="date"
                        v-model="currentMember.available.endDate"
                        value-format="timestamp"
                        placeholder=""
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" v-if="associationAndAbove">
                    <el-form-item label="Available Notes">
                      <el-input placeholder="" v-model="currentMember.available.notes"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="10">
                  <el-col
                    :span="24"
                    v-if="associationAndAbove && currentMember.statusNotesList.length > 0"
                  >
                    <el-form-item label="Status Notes">
                      <el-timeline style="padding-left: 10px">
                        <el-timeline-item
                          v-for="(item, index) in currentMember.statusNotesList"
                          :key="index"
                          color="#098255"
                        >
                          {{ item.notes }}

                          <!-- element ui built in classes -->
                          <div class="el-timeline-item__timestamp is-bottom">
                            {{ item.is ? "Available on " : "Unavailable on " }} -
                            {{ formatTimestamp(item.createdAt) }}
                            {{ item.createdBy.firstName ? "-" : "" }}
                            {{ item.createdBy.firstName || "" }}
                            {{ item.createdBy.lastName || "" }}
                          </div>
                        </el-timeline-item>
                      </el-timeline>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item title="Rewards" name="7" v-if="isSuperAdmin">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item>
                      <el-input
                        disabled
                        placeholder="Code"
                        v-model="currentMember.reward"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" v-if="!currentMember.reward || currentMember.reward === 'TBC'">
                    <el-button @click="issueReward"> Issue Reward </el-button>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
            <el-row :gutter="10" class="action-buttons">
              <el-col :span="8">
                <action-buttons
                  submit
                  cancel
                  @submit="submitForm('currentMember')"
                  @cancel="cancelForm()"
                />
              </el-col>
            </el-row>
          </el-form>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Registration History">
        <registration-history :member="currentMember" />
      </el-tab-pane>
      <el-tab-pane label="Competition Case History">
        <competition-cases-history :currentMember="currentMember" />
      </el-tab-pane>
      <el-tab-pane label="Clearance History">
        <clearances :clearances="clearances" :member="currentMember" />
      </el-tab-pane>
      <el-tab-pane label="Accreditations">
        <accreditations :member="currentMember" />
      </el-tab-pane>
      <el-tab-pane label="Player Stats">
        <player-stats :stats="playerStats" />
      </el-tab-pane>
      <el-tab-pane label="Dispensations">
        <dispensations :member="currentMember" :currentSeason="currentSeason" />
      </el-tab-pane>
      <el-tab-pane label="Documents">
        <documents :member="currentMember" />
      </el-tab-pane>
      <el-tab-pane v-if="isSuperAdmin" label="Teams">
        <teams :member="currentMember" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import _ from "lodash";
import ActionButtons from "@/components/ActionButtons.vue";
import {
  headshotUploadURL,
  errormsg,
  roles,
  states,
  wwcStatus,
  memberTypes,
  disabilityTypes,
} from "../../utils/constants";

import { validatePhoneNumber, validateEmail } from "../../utils/utilities";

import RegistrationHistory from "./RegistrationHistory.vue";
import CompetitionCasesHistory from "./CompetitionCasesHistory.vue";
import Accreditations from "./Accreditations.vue";
import PlayerStats from "./PlayerStats.vue";
import Dispensations from "./Dispensations.vue";
import Documents from "./Documents.vue";
import Clearances from "./Clearances.vue";
import Teams from "./Teams.vue";
import { EventBus } from "../../bus";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const valValidate = (obj, value, callback) => {
  if (value == null || value === "") {
    return callback(new Error(errormsg.input_option));
  }
  return callback();
};

const validateProfileEmail = (obj, value, callback) => {
  if (value && !validateEmail(value)) {
    return callback(new Error(errormsg.email));
  }
  return callback();
};

const validatePostalCode = (obj, value, callback) => {
  const isPostamCodeANumber = /^\d+$/.test(value);
  if (value === "" || !isPostamCodeANumber) {
    return callback(new Error(errormsg.postCode));
  }
  return callback();
};

export default {
  components: {
    ActionButtons,
    RegistrationHistory,
    CompetitionCasesHistory,
    Accreditations,
    Clearances,
    PlayerStats,
    Dispensations,
    Documents,
    Teams,
  },
  data() {
    return {
      preferredCountries: ["AU", "NZ", "WS", "TO", "PG", "CK", "US", "FJ", "GB", "FR"],
      props: {
        showSearchBox: true,
        showFlags: true,
        showDialCodeInList: true,
        showDialCodeInSelection: true,
      },
      mobileMetaData: undefined,
      isValidInternationalMobile: true,
      isValidParentInternationalMobile: true,
      isValidEmergencyInternationalMobile: true,
      initialAvailability: true,
      initialRegistrationEligibility: {},
      currentMember: {
        _id: "",
        registrationEligibility: {
          isNotEligible: undefined,
          reviewDate: undefined,
          note: undefined,
          adminAlertEmail: undefined,
          timestamp: undefined,
          updatedBy: undefined,
          history: [],
        },
        profile: {
          firstName: "",
          middleName: "",
          preferFirstName: "",
          preferLastName: "",
          preferNameStatus: false,
          lastName: "",
          dob: "",
          gender: "",
          mobile: "",
          email: "",
          isPrivate: false,
          address: {
            addressLine1: "",
            addressLine2: "",
            country: "",
            state: "",
            suburb: "",
            postcode: "",
          },
          meta: {
            preferredName: "",
            maidenName: "",
            developmentFee: false,
            parentOrGuardian1: {
              name: "",
              email: "",
              mobile: "",
              countryCallingCode: "",
              countryCode: "",
            },
            height: 0,
            weight: 0,
            medical: {
              hasMedical: false,
              hasAllergies: false,
              allowedTreatment: false,
              notes: "",
            },
            disability: {
              hasDisability: "",
              disabilityType: "",
            },
            imcId: 0,
            wwc: {
              number: null,
              expiry: "",
              status: "",
              issuer: "",
            },
            contractEntered: "",
            deceased: false,
          },
          emergency: {
            name: "",
            mobile: "",
            countryCallingCode: "",
            countryCode: "",
          },
        },
        available: {
          is: true,
          endDate: 1,
          notes: "",
        },
        memberRegos: [],
        dispensations: [],
        documents: [],
        teams: [],
        accreditations: [],
        defaulter: false,
        avatar: false,
        reward: undefined,
        statusNotesList: [],
      },
      allStatesWithCities: [],
      cities: [],
      countries: [],
      rules: {
        "profile.firstName": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
        "profile.lastName": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
        "profile.dob": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
        "profile.gender": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
        "profile.mobile": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
        "profile.email": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: validateProfileEmail,
        },
        "profile.address.addressLine1": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
        "profile.address.country": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
        "profile.address.state": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
        "profile.address.suburb": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
        "profile.address.postcode": {
          required: true,
          // message: errormsg.input_option,
          trigger: "blur",
          validator: validatePostalCode,
        },
        "profile.emergency.mobile": {
          message: errormsg.phone,
          trigger: "blur",
        },
        "available.endDate": {
          message: errormsg.input_option,
          trigger: "blur",
          validator: valValidate,
        },
      },
      currentSeason: 2022,
      playerStats: [],
      clearances: [],
      uploads: [],
      activeNames: ["0", "1"],
      extensions: "jpg,jpeg,png",
      accept: "image/png,image/jpg,image/jpeg",
      headshotUploadURL,
      wwcStatus,
      states,
      disabilityTypes,
      memberTypes,
      teamId: null,
    };
  },
  mounted() {
    this.$store.commit("root/loading", true);
    const { id, teamId } = this.$route.params;
    this.teamId = teamId;
    Promise.all([
      this.$http.get(`/nrl/api/v1/admin/members/${id}`),
      this.$http.get("/nrl/api/v1/admin/seasons/current"),
      this.$http.get(`/nrl/api/v1/admin/members/${id}/player-stats`),
      this.$http.get(`/nrl/api/v1/admin/members/${id}/clearances`),
      this.$http.get("/nrl/api/v1/admin/getcountries?type=country"),
    ])
      .then(async ([memberRes, seasonRes, statsRes, clearanceRes, countries]) => {
        this.countries = countries.data.data.map((country) => ({
          value: country.name,
          label: country.name,
        }));
        const userCountry = memberRes.data.data.profile?.address?.country;
        const isMemberCountryRight = countries.data.data.some((country) => {
          return country.name === userCountry;
        });
        if (!isMemberCountryRight && memberRes.data.data.profile.address) {
          memberRes.data.data.profile.address.country = "";
        }
        const userState = memberRes.data.data.profile?.address?.state;
        if (userCountry && userState) {
          await this.onCountryChange(userCountry);
          const selectedState = await this.onStateChange(userState);
          if (selectedState === undefined) {
            memberRes.data.data.profile.address.state = "";
            memberRes.data.data.profile.address.suburb = "";
          }
        }
        this.currentSeason = seasonRes.data.data.season;
        this.playerStats = _.orderBy(statsRes.data.data, ["competition.season"], ["desc"]);
        this.clearances = _.orderBy(
          clearanceRes.data.data,
          ["meta.clearanceYear", "createdAt"],
          ["desc", "desc"]
        );
        this.currentMember = _.merge({}, this.currentMember, memberRes.data.data);
        if (
          !this.currentMember.profile.preferFirstName &&
          !this.currentMember.profile.preferLastName
        ) {
          this.currentMember.profile.preferFirstName = this.currentMember.profile.meta.preferredName
            ? this.currentMember.profile.meta.preferredName
            : "";
        }

        if (this.currentMember.available)
          this.initialAvailability = this.currentMember.available.is;

        if (this.currentMember.registrationEligibility) {
          this.initialRegistrationEligibility = { ...this.currentMember.registrationEligibility };
        }

        const { meta } = memberRes.data.data.profile;
        if (meta && meta.avatar) {
          this.currentMember.avatar = meta.avatar;
        }

        EventBus.$emit("memberUpdated");
        this.$store.commit("root/loading", false);
      })
      .catch(() => {
        this.$store.commit("root/loading", false);
        this.$customError();
      });
  },
  methods: {
    async issueReward() {
      try {
        this.$store.commit("root/loading", true);
        const memberRes = await this.$http.put(`/nrl/api/v1/admin/members/issue/reward`, {
          id: this.currentMember._id,
          email: this.currentMember.profile.email,
        });
        this.currentMember.reward = memberRes.data.code;
        this.$store.commit("root/loading", false);
      } catch (err) {
        this.$customError("Unable to issue reward");
        this.$store.commit("root/loading", false);
      }
    },
    validateMobile(data) {
      if (!data | !data.number | !data.countryCode) {
        return;
      }
      const parsedNumber = parsePhoneNumberFromString(data.number, data.countryCode);
      if (parsedNumber.isValid()) {
        this.isValidInternationalMobile = true;
        const formattedNumber = parsedNumber.formatInternational();

        // Update the model with the formatted number
        this.currentMember.profile.mobile = formattedNumber;
      } else {
        this.isValidInternationalMobile = false;
      }
      this.mobileMetaData = data;
    },
    handlePaste(number, phoneObject) {
      if (phoneObject.formatted) {
        this.currentMember.profile.mobile = phoneObject.formatted;
      }
      // Trigger the validation method
      this.validateMobile(phoneObject);
    },
    validateParentMobile(data) {
      if (!data | !data.number | !data.countryCode) {
        return;
      }
      const parsedNumber = parsePhoneNumberFromString(data.number, data.countryCode);
      if (parsedNumber.isValid()) {
        this.isValidParentInternationalMobile = true;
        const formattedNumber = parsedNumber.formatInternational();

        // Update the model with the formatted number
        this.currentMember.profile.meta.parentOrGuardian1.mobile = formattedNumber;
        this.currentMember.profile.meta.parentOrGuardian1.countryCode = data.countryCode;
        this.currentMember.profile.meta.parentOrGuardian1.countryCallingCode =
          data.countryCallingCode;
      } else {
        this.isValidParentInternationalMobile = false;
      }
    },
    handleParentPaste(number, phoneObject) {
      if (phoneObject.formatted) {
        this.currentMember.profile.meta.parentOrGuardian1.mobile = phoneObject.formatted;
      }
      // Trigger the validation method
      this.validateParentMobile(phoneObject);
    },
    validateEmergencyMobile(data) {
      if (!data | !data.number | !data.countryCode) {
        return;
      }
      const parsedNumber = parsePhoneNumberFromString(data.number, data.countryCode);
      if (parsedNumber.isValid()) {
        this.isValidEmergencyInternationalMobile = true;
        const formattedNumber = parsedNumber.formatInternational();

        // Update the model with the formatted number
        this.currentMember.profile.emergency.mobile = formattedNumber;
        this.currentMember.profile.emergency.countryCode = data.countryCode;
        this.currentMember.profile.emergency.countryCallingCode = data.countryCallingCode;
      } else {
        this.isValidEmergencyInternationalMobile = false;
      }
    },
    handleEmergencyPaste(number, phoneObject) {
      if (phoneObject.formatted) {
        this.currentMember.profile.emergency.mobile = phoneObject.formatted;
      }
      // Trigger the validation method
      this.validateEmergencyMobile(phoneObject);
    },
    preferredNameChanged() {
      this.currentMember.profile.preferNameStatus = false;
    },
    formatTimestamp(time) {
      return this.moment(time).format("MMMM Do YYYY, h:mm:ss a");
    },
    async onCommandDropdown(command) {
      if (command === "replace-image") {
        const uploader = this.$refs.headshotupload;
        uploader.$children[0].$el.click();
      }
      if (command === "remove-image") {
        try {
          this.uploads = [];
          this.$store.commit("root/loading", true);
          await this.$http.post(`${headshotUploadURL}`, {
            _id: this.currentMember._id,
          });
          this.currentMember.avatar = "";
          this.$store.commit("root/loading", false);
        } catch (e) {
          this.$store.commit("root/loading", false);
        }
      }
    },
    formatMemberType(type) {
      const found = memberTypes.find((t) => t.type === type);
      return found ? found.name : type;
    },
    formatStatus() {
      if (this.currentMember.verification && this.currentMember.verification.pending)
        return "Pending";
      if (this.currentMember.available && this.currentMember.available.is === false)
        return "Suspended";
      return "Registered";
    },
    setAvailableDate(value) {
      if (value === false) {
        const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
        const oneYearFromNow = currentDate.setFullYear(currentDate.getFullYear() + 1);
        this.currentMember.available.endDate = oneYearFromNow;
      } else if (value === true && this.initialAvailability === false) {
        const currentDate = new Date().setHours(0, 0, 0, 0);
        this.currentMember.available.endDate = currentDate;
      }
    },
    async onCountryChange(value) {
      try {
        const res = await this.$http.get(
          `/nrl/api/v1/admin/getcountries?type=state&countryName=${value}`
        );
        if (res?.data) {
          this.currentMember.profile.address.state = "";
          this.states = [];
          this.cities = [];
          this.allStatesWithCities = res.data.data;
          this.states = res.data.data.map((state) => ({
            value: state.states.name,
            label: state.states.name,
          }));
        }
      } catch (e) {
        this.PUSH_NOTIFICATION({
          msg: msg.error.apiError,
          route: this.$route.name,
          type: "warning",
        });
      }
    },
    async onStateChange(value) {
      this.currentMember.profile.address.city = "";
      this.cities = [];
      const selectedState = this.allStatesWithCities?.find(
        (state) => state.states.name === value || state.states.state_code === value
      );
      if (selectedState !== undefined) {
        this.cities = selectedState.states.cities.map((city) => ({
          value: city.name,
          label: city.name,
        }));
      }
      return selectedState;
    },
    cancelForm() {
      //Check if the previous path is pending registration:
      const checkPath = this.prevPath.indexOf("pending-registrations");
      if (checkPath === -1) return this.$router.go(-1);
      const routeObj = {
        name: "pending-registrations.details",
        params: {
          id: this.currentMember._id,
          member: this.currentMember,
        },
      };
      return this.$router.push(routeObj);

      // this.$router.go(-1);

      // if (this.teamId) {
      //   this.$router.push({
      //     name: "teams.update",
      //     params: {
      //       id: this.teamId
      //     }
      //   });
      // } else {
      //   this.$router.push({
      //     name: "members.list"
      //   });
      // }
    },
    async submitForm(formName) {
      try {
        if (
          this.currentMember.profile.preferFirstName.length > 0 ||
          this.currentMember.profile.preferLastName.length > 0
        ) {
          const nameRegex = /^[a-zA-Z\s'-]*$/;

          if (
            !nameRegex.test(this.currentMember.profile.preferFirstName) ||
            !nameRegex.test(this.currentMember.profile.preferLastName)
          ) {
            this.$customError(
              "Please provide a valid text for preferred first and last name with only alphabetic characters and/or whitespace, apostrophe & hyphen."
            );
            return;
          }

          if (!this.currentMember.profile.preferNameStatus) {
            this.$customError("Please verify the approved preferred name change.");
            return;
          }
        }

        // Validate mobile number
        if (!this.isValidInternationalMobile) {
          this.$customError("Please check the entered mobile number.");
          return;
        }

        // loader
        this.$store.commit("root/loading", true);

        // check the form
        const validPromise = await new Promise((res) => {
          this.$refs[formName].validate((valid) => {
            valid ? res(true) : res(false);
          });
        });
        if (!validPromise)
          throw new Error(
            "Could not submit. Please check all your required fields marked by a asterix."
          );

        // create an update object which is a deep clone. TODO simplify
        const update = _.cloneDeep(this.currentMember);

        // standard deletes
        delete update.avatar;
        delete update.profile.meta.avatar;
        delete update.memberRegos;
        delete update.accreditations;
        delete update.teams;
        delete update.dispensations;
        delete update.documents;
        // pruning, not a great fan of how this has been done
        if (update.profile.middleName === "") delete update.profile.middleName;
        if (update.profile.email === "") delete update.profile.email;
        if (!validateEmail(update.profile.email)) delete update.profile.email;
        if (!update.profile.meta.imcId) delete update.profile.meta.imcId;
        if (update.profile.meta.height === 0) delete update.profile.meta.height;
        if (update.profile.meta.weight === 0) delete update.profile.meta.weight;
        if (update.profile.meta.parentOrGuardian1.email === "")
          delete update.profile.meta.parentOrGuardian1.email;
        if (!validateEmail(update.profile.meta.parentOrGuardian1.email))
          delete update.profile.meta.parentOrGuardian1.email;
        // if (update.available && update.available.is === true) {
        //   delete update.available.endDate;
        //   delete update.available.notes;
        // }
        if (update.profile.meta.disability.hasDisability === "") {
          delete update.profile.meta.disability.hasDisability;
          delete update.profile.meta.disability.disabilityType;
        }
        if (update.profile.meta.disability.hasDisability !== "yes")
          delete update.profile.meta.disability.disabilityType;
        if (!this.associationAndAbove) delete update.available;

        if (update.available) {
          // Track Status list
          update.available.endDate = this.currentMember.available.endDate
            ? parseInt(this.currentMember.available.endDate)
            : 1;

          // check if update availability is changing to true then check the update has changed from initial value
          if (
            update.available &&
            update.available.is === true &&
            this.initialAvailability !== update.available.is
          ) {
            // the confim model is mainly used in a negative way. It throws an error on cancel, while on click away it exits the function.
            await this.$confirm(
              "You are marking this participant as available, please confirm you have sighted the medical clearance form, if required.",
              "Warning",
              {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            );
          }
        }

        if (this.currentMember.registrationEligibility.isNotEligible === undefined) {
          update.registrationEligibility = undefined;
        } else if (
          update.registrationEligibility &&
          JSON.stringify(this.initialRegistrationEligibility) !==
            JSON.stringify(update.registrationEligibility)
        ) {
          this.initialRegistrationEligibility.history = undefined;
          update.registrationEligibility.history.push(this.initialRegistrationEligibility);
          update.registrationEligibility.timestamp = Date.now();
          update.registrationEligibility.updatedBy = this.$store.getters["user/userid"];
        }
        /**
         * INternational mobile number changes
         */
        let mobileMeta;

        if (this.mobileMetaData) {
          mobileMeta = {
            originalNumber: this.mobileMetaData.number,
            countryRegion: this.mobileMetaData.countryCode,
            countryCode: this.mobileMetaData.countryCallingCode,
            number: this.mobileMetaData.number,
          };
          update.profile.meta.mobile = mobileMeta;
        }
        // send api update
        const url = `/nrl/api/v1/admin/members/${this.currentMember._id}`;
        await this.$http.put(url, update);
        // update the vuex store
        this.$store.commit("members/updateMember", update);
        // reset the initial availability to compare changes
        this.initialAvailability = this.currentMember.available.is;
        // stop loading spinner and let user know status
        this.$store.commit("root/loading", false);
        this.$customSuccess();
      } catch (e) {
        // reset the initial availability to compare changes
        this.initialAvailability = this.currentMember.available.is;
        // stop loading spinner and let user know status
        this.$store.commit("root/loading", false);
        const apiError = _.get(e, "response.data.message");
        const standError = e.message;
        if (e !== "cancel") this.$customError(apiError ? apiError : standError);
      }
    },
    inputFile(newFile) {
      if (newFile && newFile.error) {
        this.$store.commit("app/loading", false);
        this.$customError("Headshot upload has failed");
        this.uploads = this.uploads.filter((file) => file.id !== newFile.id);
      } else if (newFile && !newFile.active && newFile.response) {
        setTimeout(() => {
          this.$set(this.currentMember, "avatar", newFile.response.url);
          this.$store.commit("app/loading", false);
        }, 2500);
      }
      return undefined;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg|pdf)$/i.test(newFile.name)) {
        this.$customError("Please submit a valid file type (png/jpg/jpeg/pdf)");
        return prevent();
      }
      this.$store.commit("app/loading", true);
      this.$refs.headshotupload.active = true;
      return undefined;
    },
  },
  computed: {
    currentSeasonRego() {
      const current = this.currentMember.memberRegos;
      const filtered = _.filter(current, (rego) => rego.season === this.currentSeason);
      return filtered;
    },
    associationAndAbove() {
      const { type } = this.$store.getters["user/activeRole"];
      return type <= roles.associationAdmin;
    },
    clubAndAbove() {
      const { type } = this.$store.getters["user/activeRole"];
      return type <= roles.clubAdmin;
    },
    memberData() {
      return {
        _id: this.currentMember._id,
      };
    },
    currentHeadshot() {
      return this.currentMember && this.currentMember.avatar
        ? this.currentMember.avatar
        : require("@/assets/player_placeholder.png");
    },
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    isClubAdmin() {
      return this.$store.getters["user/activeRole"].type === roles.clubAdmin;
    },
    isSuperAdmin() {
      return this.$store.getters["user/activeRole"].type === roles.superAdmin;
    },
    isStateAdmin() {
      return this.$store.getters["user/activeRole"].type === roles.stateAdmin;
    },
    isAssociationAdmin() {
      return this.$store.getters["user/activeRole"].type === roles.associationAdmin;
    },
    isTfaAdmin() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
    isNrlAdmin() {
      return this.$store.getters["user/activeRole"].national_id === 32;
    },
    isViewOnlyAdmin() {
      return (
        this.$store.getters["user/activeRole"].type === 200 ||
        this.$store.getters["user/activeRole"].type === 201
      );
    },
    prevPath() {
      return this.$store.getters["app/getPreviousPath"];
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-box {
  text-align: center;
  .avatar-wrap {
    background-color: #fff;
    margin: 0 auto;
    overflow: hidden;
    border: 5px solid #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 8rem;
    height: 8rem;

    .upload-logo {
      width: 100%;
      height: 100%;
      position: relative;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: black;
      opacity: 0.6;
    }

    .avatar {
      background-size: contain;
      background-position: center;
      height: 8rem;
    }
  }

  img {
    transform: scale(1.4);
  }

  overflow: hidden;

  .avatar-entity-name {
    text-align: center;
    display: block;
    font-size: 1.05rem;
    font-style: oblique;
    margin-bottom: 1rem;
  }
  .upload {
    margin: 0 auto;
    display: block;
    max-width: 30%;
    margin-bottom: 2rem;
  }
}

.el-select,
.el-input {
  width: 100%;
}

.go-back-button {
  margin-bottom: 1rem;
}

.action-buttons {
  margin-top: 1.25rem;
}

::v-deep .nrl-id .el-input__inner {
  font-weight: bold !important;
}

.el-checkbox {
  display: flex;
  align-items: flex-start;
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.invalidMobile {
  color: red;
  font-size: small;
}
</style>
