import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "./styles/element-variables.scss";
import "element-ui/lib/theme-chalk/index.css";
import moment from "moment";
import Sortable from "sortablejs";
import VueDataTables from "vue-data-tables";
import Vue from "vue";
import VueGtm from "vue-gtm";
import VueMq from "vue-mq";
import * as VueGoogleMaps from "vue2-google-maps";
import VueUploadComponent from "vue-upload-component";
import VueClipboards from "vue-clipboards";
import VueGoogleAutocomplete from "./components/VueGoogleAutocomplete.vue";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import App from "./App.vue";
import router from "./router";

import LoginLayout from "./layouts/LoginLayout.vue";
import AppLayout from "./layouts/AppLayout.vue";
import LoadingLayout from "./layouts/LoadingLayout.vue";
import TeamListType from "./views/associations/TeamListTypes.vue";

import httpnoauth from "./axios/httpnoauth";
import httpauth from "./axios/httpauth";
import httpms from "./axios/httpms";
import httpmsnoauth from "./axios/httpmsnoauth";

import httpgraphqlapi from "./axios/httpgraphqlapi";
import httpauth_apikeys_beta from "./axios/httprealmauthapi";
import store from "./store";

import "./index.css";

const JSZip = require("jszip");

// TODO lazy load packages to help webpack code splitting
//const pdfMake = () => import("pdfmake/build/pdfmake.js");
//const pdfFonts = () => import("pdfmake/build/vfs_fonts.js");

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

Vue.component("login-layout", LoginLayout);
Vue.component("app-layout", AppLayout);
Vue.component("loading-layout", LoadingLayout);
Vue.component("vue-upload-component", VueUploadComponent);
Vue.component("google-auto-complete", VueGoogleAutocomplete);
Vue.component("team-list-type", TeamListType);

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    sm: 450,
    md: 1024,
    lg: Infinity,
  },
});

Vue.use(VueTelInput);


Vue.config.productionTip = false;
Vue.prototype.pdfMake = pdfMake;
Vue.prototype.Zipper = JSZip;
Vue.prototype.moment = moment;
Vue.prototype.sortable = Sortable;
Vue.prototype.$noauth = httpnoauth;
Vue.prototype.$http = httpauth;
Vue.prototype.$httpms = httpms;
Vue.prototype.$httpmsnoauth = httpmsnoauth;

Vue.prototype.$httpgraphqlapi = httpgraphqlapi;
Vue.prototype.$httpauth_apikeys_beta = httpauth_apikeys_beta;
Vue.prototype.$customError = (msg) => {
  ElementUI.Notification.error({
    title: "Error",
    message: msg || "There has been an error",
    duration: 10000,
  });
};
Vue.prototype.$customSuccess = (msg) => {
  ElementUI.Notification({
    title: "Success",
    message: msg || "",
    type: "success",
    customClass: "success",
  });
};

Vue.use(VueClipboards);
Vue.use(ElementUI, { locale });
Vue.use(VueDataTables);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCOYsTyiTBiLNV-YFbGgUWZVpHt8gL-rHY",
    libraries: "places",
  },
});

Vue.use(VueGtm, {
  id: "GTM-PV42QSK",
});

Vue.filter("capitalize", (value) => {
  if (!value) return "";
  let text = value;
  text = text.toString();
  return text.charAt(0).toUpperCase() + text.slice(1);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
