<template>
  <div>
    <el-form label-position="top" :model="currentOptions" :rules="rules" ref="regiproduct">
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <div>
          <el-form-item prop="role" label="Member Type">
            <el-select v-model="role" placeholder="Member Type" filterable>
              <el-option
                v-for="role in availableRoles"
                :label="role.name"
                :key="role.type"
                :value="role.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="allGenders" label="Gender">
            <el-select v-model="gender" placeholder="Gender">
              <el-option v-for="item in allGenders" :label="item" :key="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="!isTFA">
          <el-form-item prop="agefrom" label="Age from">
            <el-input type="number" autocomplete="off" v-model="agefrom" placeholder="Age from" />
          </el-form-item>
        </div>
        <div v-if="!isTFA">
          <el-form-item prop="ageto" label="Age to">
            <el-input type="number" autocomplete="off" v-model="ageto" placeholder="Age to" />
          </el-form-item>
        </div>
        <div v-if="isTFA">
          <el-form-item prop="datefrom" label="DOB from">
            <el-date-picker
              v-model="datefrom"
              type="date"
              placeholder="Select a Date"
              format="dd/MM/yyyy"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div v-if="isTFA">
          <el-form-item prop="dateto" label="DOB to">
            <el-date-picker
              v-model="dateto"
              type="date"
              placeholder="Select a Date"
              format="dd/MM/yyyy"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100"
      >
        <el-col :span="6">
          <el-form-item prop="price" label="Price">
            <el-input type="text" autocomplete="off" v-model="price" placeholder="Price" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="status" label="Status">
            <el-select v-model="status" placeholder="Status">
              <el-option
                v-for="item in allStatus"
                :label="item.status"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            v-if="clearance === roles.clubAdmin"
            prop="payment"
            label="Online payment required"
          >
            <el-select v-model="payment" placeholder="Online payment required">
              <el-option
                v-for="item in allPayments"
                :label="item.payment"
                :key="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </div>
      <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-pt-3">
        <el-col :span="6">
          <el-form-item class="btn-container">
            <el-button type="success" @click="submitForm('regiproduct')">Add</el-button>
          </el-form-item>
        </el-col>
      </div>
    </el-form>

    <ProductData
      :regiProduct="clubRegoProduct"
      v-if="clubVisible"
      id="club"
      :heading="'Club'"
      :edit="editableClub"
      :remove="removalClub"
    />
    <ProductData
      :regiProduct="assocRegoProduct"
      v-if="assocVisible"
      id="Association"
      :heading="'Association'"
      :edit="editableAssoc"
      :remove="removalAssoc"
    />
    <ProductData
      :regiProduct="nationalRegoProduct"
      v-if="nationalVisible"
      id="National"
      :heading="'National'"
      :edit="editableNational"
      :remove="removalNational"
    />
    <ProductData
      :regiProduct="stateRegoProduct"
      v-if="stateVisible"
      id="State"
      :heading="'State'"
      :edit="editableState"
      :remove="removalState"
    />
    <ProductData
      :regiProduct="regionRegoProduct"
      v-if="regionVisible"
      id="Region"
      :heading="'Region'"
      :edit="editableRegion"
      :remove="removalRegion"
    />
  </div>
</template>

<script>
import { get } from "lodash";
import filter from "lodash/fp/filter";
import contains from "lodash/fp/contains";
import ProductData from "./ProductsData.vue";
import {
  errormsg,
  memberTypes,
  genders,
  roles,
  nationals,
  registrationStatus,
} from "../../utils/constants";

export default {
  name: "Products",
  // props: ["regiProduct"],
  props: [
    "regiProduct",
    "nationalRegoProduct",
    "stateRegoProduct",
    "regionRegoProduct",
    "assocRegoProduct",
    "clubRegoProduct",
  ],
  components: {
    ProductData,
  },
  mounted() {
    const { type, entity, national_id } = this.$store.getters["user/activeRole"];
    const { _id } = entity;
    if (type === roles.clubAdmin) {
      this.$http
        .get(`/nrl/api/v1/admin/clubs/${_id}`)
        .then((clubRes) => {
          const clubData = clubRes.data.data;
          if (clubData.memberTypes) {
            this.availableRoles = filter((role) => contains(role.type)(clubData.memberTypes))(
              this.allroles
            );
          }
          this.$store.commit("root/loading", false);
        })
        .catch((e) => {
          this.$store.commit("root/loading", false);
          this.$customError(e);
        });
    } else if (national_id === nationals.TFA) {
      this.availableRoles = this.allroles.filter((r) => r.tfa === true);
    } else {
      this.availableRoles = this.allroles;
    }
  },
  data() {
    const validateRole = (rule, value, callback) => {
      if (this.currentOptions.role === "") {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const validateGender = (rule, value, callback) => {
      if (this.currentOptions.gender === "") {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const validateAgeFrom = (rule, value, callback) => {
      if (!this.currentOptions.agefrom || parseInt(this.currentOptions.agefrom) <= 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateAgeTo = (rule, value, callback) => {
      if (!this.currentOptions.ageto || parseInt(this.currentOptions.ageto) <= 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDateFrom = (rule, value, callback) => {
      // if (!this.currentOptions.agefrom || parseInt(this.currentOptions.agefrom) <= 0) {
      //   return callback(new Error(errormsg.input_option));
      // }
      return callback();
    };
    const validateDateTo = (rule, value, callback) => {
      // if (!this.currentOptions.ageto || parseInt(this.currentOptions.ageto) <= 0) {
      //   return callback(new Error(errormsg.input_option));
      // }
      return callback();
    };
    const validatePrice = (rule, value, callback) => {
      if (!this.currentOptions.price || parseFloat(this.currentOptions.price) < 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateStatus = (rule, value, callback) => {
      if (this.currentOptions.status == null) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validatePayment = (rule, value, callback) => {
      if (this.currentOptions.payment == null) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };

    return {
      // nationalRegoProduct: [],
      // stateRegoProduct: [],
      // regionRegoProduct: [],
      // assocRegoProduct: [],
      // clubRegoProduct: [],
      // registerProduct: {},
      currentOptions: {
        role: "",
        gender: "",
        agefrom: -1,
        ageto: -1,
        datefrom: undefined,
        dateto: undefined,
        price: -1,
        status: false,
        payment: false,
      },
      optionsdisabled: [],
      allroles: memberTypes,
      availableRoles: [],
      allStatus: [
        { status: "Open", value: true },
        { status: "Closed", value: false },
      ],
      allPayments: [
        { payment: "Yes", value: true },
        { payment: "No", value: false },
      ],
      allGenders: genders,
      rules: {
        role: {
          type: "text",
          required: true,
          validator: validateRole,
          message: errormsg.select_option,
          trigger: "blur",
        },
        allGenders: {
          type: "text",
          required: true,
          validator: validateGender,
          message: errormsg.select_option,
          trigger: "blur",
        },
        agefrom: {
          required: true,
          validator: validateAgeFrom,
          message: errormsg.input_option,
          trigger: "blur",
        },
        ageto: {
          required: true,
          validator: validateAgeTo,
          message: errormsg.input_option,
          trigger: "blur",
        },
        datefrom: {
          required: false,
          validator: validateDateFrom,
          message: errormsg.input_option,
          trigger: "blur",
        },
        dateto: {
          required: false,
          validator: validateDateTo,
          message: errormsg.input_option,
          trigger: "blur",
        },
        price: {
          required: true,
          validator: validatePrice,
          message: errormsg.input_option,
          trigger: "blur",
        },
        status: {
          required: true,
          validator: validateStatus,
          trigger: "change",
        },
        payment: {
          required: true,
          validator: validatePayment,
          trigger: "change",
        },
      },
      tableProps: {
        border: true,
      },
      roles,
    };
  },
  methods: {
    submitForm(formName) {
      if (this.$store.getters["user/activeRole"].national_id === 31) {
        const productTypeRoles = this.regiProduct.products
          .filter(
            (product) =>
              typeof product.agefrom === "number" &&
              typeof product.ageto === "number" &&
              (typeof product.datefrom !== "number" || isNaN(product.datefrom)) &&
              (typeof product.dateto !== "number" || isNaN(product.dateto))
          )
          .map((product) => product.role);

        if (productTypeRoles && productTypeRoles.includes(this.currentOptions.role)) {
          this.$customError(errormsg.regiproduct_role_exists_with_age);
          return false;
        }
      }

      try {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (parseInt(this.currentOptions.agefrom) > parseInt(this.currentOptions.ageto)) {
              this.$customError(errormsg.max_min);
              return false;
            }
            if (parseInt(this.currentOptions.datefrom) > parseInt(this.currentOptions.dateto)) {
              this.$customError(errormsg.gte_date);
              return false;
            }
            const { role, gender, agefrom, ageto, datefrom, dateto, payment, status } =
              this.currentOptions;

            let overlapping;
            if (datefrom && dateto) {
              this.regiProduct.products.find(
                (o) =>
                  role === o.role &&
                  gender === o.gender &&
                  o.datefrom &&
                  o.dateto &&
                  !(parseInt(o.datefrom) > dateto || parseInt(o.dateto) < datefrom)
              );
            } else {
              this.regiProduct.products.find(
                (o) =>
                  role === o.role &&
                  gender === o.gender &&
                  o.agefrom &&
                  o.ageto &&
                  !(parseInt(o.agefrom) > ageto || parseInt(o.ageto) < agefrom)
              );
            }

            if (overlapping) {
              this.$customError(errormsg.age_overlap);
              return false;
            }

            // Update registration products
            this.$store.commit("root/loading", true);
            this.regiProduct.entity_type = Object.keys(roles)
              .find((key) => roles[key] === this.$store.getters["user/activeRole"].type)
              .replace("Admin", "");
            this.regiProduct.entity_id = this.$store.getters["user/activeRole"].entity._id;
            const product = {
              role,
              gender,
              agefrom,
              ageto,
              datefrom,
              dateto,
              price: "$ ".concat(parseFloat(this.currentOptions.price).toFixed(2).toString()),
              price_int: parseInt(parseFloat(this.currentOptions.price).toFixed(2) * 100),
              payment,
              status,
            };

            const insert = {
              memberType: product.role,
              gender: product.gender,
              ageFrom: product.agefrom,
              ageTo: product.ageto,
              dateFrom: product.datefrom,
              dateTo: product.dateto,
              price: product.price_int,
              isRegoOpen: product.status != null ? product.status : false,
              onlinePaymentRequired: product.payment != null ? product.payment : false,
            };

            this.currentOptions = {
              role: "",
              gender: "",
              agefrom: -1,
              ageto: -1,
              datefrom: undefined,
              dateto: undefined,
              price: -1,
              payment: false,
              status: false,
            };

            const url = "/nrl/api/v1/admin/registration-products/products";
            this.$http
              .post(url, insert)
              .then((response) => {
                product._id = response.data.data._id;
                if (product.agefrom === -1) {
                  delete product.agefrom;
                }
                if (product.ageto === -1) {
                  delete product.ageto;
                }

                switch (this.$store.getters["user/activeRole"].type) {
                  case 1:
                    this.nationalRegoProduct.push(product);
                    break;
                  case 2:
                    this.stateRegoProduct.push(product);
                    break;
                  case 3:
                    this.regionRegoProduct.push(product);
                    break;
                  case 4:
                    this.assocRegoProduct.push(product);
                    break;
                  case 5:
                    this.clubRegoProduct.push(product);
                    break;
                }

                this.optionsdisabled.push(false);

                this.$store.commit("root/loading", false);

                this.$customSuccess();
              })
              .catch((error) => {
                this.$store.commit("root/loading", false);
                this.$customError(get(error, "response.data.message"));
              });
            return true;
          }
          return false;
        });
      } catch (e) {
        this.$customError(e);
      }
    },
    handleEditRow(rowindex) {
      if (this.optionsdisabled[rowindex]) {
        const row = this.regiProduct[rowindex];
        if (row.agefrom && row.ageto) {
          if (!parseInt(row.agefrom)) {
            this.$customError(errormsg.min_age);
            return false;
          }
          if (!parseInt(row.ageto)) {
            this.$customError(errormsg.max_age);
            return false;
          }
          if (parseInt(row.ageto) < parseInt(row.agefrom)) {
            this.$customError(errormsg.max_min);
            return false;
          }
        }
        if (row.datefrom && row.dateto) {
          if (row.datefrom === null) {
            this.$customError(errormsg.date_from);
            return false;
          }
          if (row.dateto === null) {
            this.$customError(errormsg.date_to);
            return false;
          }
          if (parseInt(row.dateto) < parseInt(row.datefrom)) {
            this.$customError(errormsg.date_from_to);
            return false;
          }
        } else {
          delete row.datefrom;
          delete row.dateto;
        }

        if (row.price.indexOf("$") === 0) {
          const processedprice = row.price
            .substring(row.price.indexOf("$") + 1, row.price.length)
            .trim();
          if (Number.isNaN(parseFloat(processedprice)) || parseFloat(processedprice) < 0) {
            this.$customError(errormsg.price_invalid);
            return false;
          }
        } else if (Number.isNaN(parseFloat(row.price)) || parseFloat(row.price) < 0) {
          this.$customError(errormsg.price_invalid);
          return false;
        }
        const current_role = row.role;
        const current_gender = row.gender;
        const current_agefrom = parseInt(row.agefrom);
        const current_ageto = parseInt(row.ageto);
        const current_datefrom = parseInt(row.datefrom);
        const current_dateto = parseInt(row.dateto);
        let overlapping = false;
        if (row.agefrom && row.ageto) {
          overlapping = this.regiProduct
            .filter((obj) => obj.hasOwnProperty("ageFrom") && obj.hasOwnProperty("ageTo"))
            .find(
              (o) =>
                row._id !== o._id &&
                current_role === o.role &&
                current_gender === o.gender &&
                !(parseInt(o.agefrom) > current_ageto || parseInt(o.ageto) < current_agefrom)
            );
        } else if (row.datefrom && row.dateto) {
          overlapping = this.regiProduct
            .filter((obj) => obj.hasOwnProperty("dateFrom") && obj.hasOwnProperty("dateTo"))
            .find(
              (o) =>
                row._id !== o._id &&
                current_role === o.role &&
                current_gender === o.gender &&
                !(parseInt(o.datefrom) > current_dateto || parseInt(o.dateto) < current_datefrom)
            );
        }

        if (overlapping) {
          this.$customError(errormsg.age_overlap);
          return false;
        }
        // Update registration products
        this.$store.commit("root/loading", true);
        if (row.price.indexOf("$") === 0) {
          this.regiProduct[rowindex].price_int = parseInt(
            parseFloat(
              row.price.substring(row.price.indexOf("$") + 1, row.price.length).trim()
            ).toFixed(2) * 100
          );
          this.regiProduct[rowindex].price = "$ ".concat(
            parseFloat(row.price.substring(row.price.indexOf("$") + 1, row.price.length).trim())
              .toFixed(2)
              .toString()
          );
        } else {
          this.regiProduct[rowindex].price_int = parseInt(parseFloat(row.price).toFixed(2) * 100);
          this.regiProduct[rowindex].price = "$ ".concat(
            parseFloat(row.price).toFixed(2).toString()
          );
        }
        const product = this.regiProduct[rowindex];
        const update = {
          memberType: product.role,
          gender: product.gender,
          ageFrom: product.agefrom ? parseInt(product.agefrom) : -1,
          ageTo: product.ageto ? parseInt(product.ageto) : -1,
          dateFrom: product.datefrom,
          dateTo: product.dateto,
          price: product.price_int,
          isRegoOpen: product.status,
          onlinePaymentRequired: product.payment,
        };

        const url = `/nrl/api/v1/admin/registration-products/products/${product._id}`;
        this.$http
          .put(url, update)
          .then(() => {
            this.$store.commit("root/loading", false);
            this.$customSuccess();
          })
          .catch((error) => {
            this.$store.commit("root/loading", false);
            this.$customError(get(error, "response.data.message"));
            this.$set(this.optionsdisabled, rowindex, true);
          });
      }
      this.$set(this.optionsdisabled, rowindex, !this.optionsdisabled[rowindex]);
      return true;
    },
    handleRemoveRow(index) {
      this.$store.commit("root/loading", true);
      this.$confirm("Are you sure you want to remove this registration fee?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const product = this.regiProduct[index];
          this.$http
            .delete(`/nrl/api/v1/admin/registration-products/products/${product._id}`)
            .then(() => {
              this.regiProduct.splice(index, 1);
              this.optionsdisabled.splice(index, 1);
              this.$store.commit("root/loading", false);
              this.$customSuccess();
            })
            .catch((error) => {
              this.$store.commit("root/loading", false);
              this.$customError(get(error, "response.data.message"));
            });
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
        });
    },
  },
  computed: {
    clubVisible() {
      return this.$store.getters["user/activeRole"].type === 5;
    },
    nationalVisible() {
      return (
        this.$store.getters["user/activeRole"].type === 5 ||
        this.$store.getters["user/activeRole"].type === 4 ||
        this.$store.getters["user/activeRole"].type === 1
      );
    },
    stateVisible() {
      return (
        this.$store.getters["user/activeRole"].type === 5 ||
        this.$store.getters["user/activeRole"].type === 4 ||
        this.$store.getters["user/activeRole"].type === 2
      );
    },
    regionVisible() {
      return (
        this.$store.getters["user/activeRole"].type === 5 ||
        this.$store.getters["user/activeRole"].type === 4 ||
        this.$store.getters["user/activeRole"].type === 3
      );
    },
    assocVisible() {
      return (
        this.$store.getters["user/activeRole"].type === 5 ||
        this.$store.getters["user/activeRole"].type === 4
      );
    },
    editableClub() {
      if (this.$store.getters["user/activeRole"].type === 5) return true;
      else return false;
    },
    editableAssoc() {
      if (this.$store.getters["user/activeRole"].type === 4) return true;
      else return false;
    },
    editableState() {
      if (this.$store.getters["user/activeRole"].type === 2) return true;
      else return false;
    },
    editableRegion() {
      if (this.$store.getters["user/activeRole"].type === 3) return true;
      else return false;
    },
    editableNational() {
      if (this.$store.getters["user/activeRole"].type === 1) return true;
      else return false;
    },
    removalClub() {
      if (this.$store.getters["user/activeRole"].type === 5) return true;
      else return false;
    },
    removalAssoc() {
      if (this.$store.getters["user/activeRole"].type === 4) return true;
      else return false;
    },
    removalState() {
      if (this.$store.getters["user/activeRole"].type === 2) return true;
      else return false;
    },
    removalRegion() {
      if (this.$store.getters["user/activeRole"].type === 3) return true;
      else return false;
    },
    removalNational() {
      if (this.$store.getters["user/activeRole"].type === 1) return true;
      else return false;
    },
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    role: {
      get() {
        return this.currentOptions.role !== "" ? this.currentOptions.role : null;
      },
      set(value) {
        this.currentOptions.role = value !== "" ? value : "";
      },
    },
    gender: {
      get() {
        return this.currentOptions.gender !== "" ? this.currentOptions.gender : null;
      },
      set(value) {
        this.currentOptions.gender = value !== "" ? value : "";
      },
    },
    agefrom: {
      get() {
        return parseInt(this.currentOptions.agefrom) > 0
          ? parseInt(this.currentOptions.agefrom)
          : null;
      },
      set(value) {
        this.currentOptions.agefrom = parseInt(value) > 0 ? parseInt(value) : -1;
      },
    },
    ageto: {
      get() {
        return parseInt(this.currentOptions.ageto) > 0 ? parseInt(this.currentOptions.ageto) : null;
      },
      set(value) {
        this.currentOptions.ageto = parseInt(value) > 0 ? parseInt(value) : -1;
      },
    },
    datefrom: {
      get() {
        return this.currentOptions.datefrom ? this.currentOptions.datefrom : undefined;
      },
      set(value) {
        this.currentOptions.datefrom = value;
      },
    },
    dateto: {
      get() {
        return this.currentOptions.dateto ? this.currentOptions.dateto : undefined;
      },
      set(value) {
        this.currentOptions.dateto = value;
      },
    },
    price: {
      get() {
        if (parseFloat(this.currentOptions.price) >= 0) {
          if (this.currentOptions.price.indexOf(".") !== -1) {
            return "$ "
              .concat(
                this.currentOptions.price.substring(0, this.currentOptions.price.indexOf("."))
              )
              .concat(".")
              .concat(
                this.currentOptions.price
                  .substring(
                    this.currentOptions.price.indexOf(".") + 1,
                    this.currentOptions.price.length
                  )
                  .substring(0, 2)
              );
          }
          return "$ ".concat(this.currentOptions.price);
        }
        return null;
      },
      set(value) {
        if (value.indexOf("$") === 0) {
          const processedvalue = value.substring(value.indexOf("$") + 1, value.length).trim();
          this.currentOptions.price =
            !Number.isNaN(parseFloat(processedvalue)) && parseFloat(processedvalue) >= 0
              ? processedvalue
              : -1;
        } else {
          this.currentOptions.price =
            !Number.isNaN(parseFloat(value.trim())) && parseFloat(value.trim()) >= 0
              ? value.trim()
              : -1;
        }
      },
    },
    status: {
      get() {
        return this.currentOptions.status ? "Open" : "Closed";
      },
      set(value) {
        this.currentOptions.status = value;
      },
    },
    payment: {
      get() {
        return this.currentOptions.payment ? "Yes" : "No";
      },
      set(value) {
        this.currentOptions.payment = value;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}
.el-pagination {
  margin-top: 1rem !important;
}
.el-select,
.el-input {
  width: 100%;
}
</style>
