<template>
  <div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col class="tw-py-3" style="width: 100%" :span="24">
        <h4 class="tw-font-bold">Team Name - {{ team.name }}</h4>
      </el-col>

      <el-col>
        <el-button class="long-btn" type="success" @click="submitPrevious"
          >ADD PREVIOUS TEAM</el-button
        >
      </el-col>
      <el-col v-if="isTfa">
        <el-button class="long-btn" type="success" @click="submitDefault"
          >ADD DEFAULT SQUAD</el-button
        >
      </el-col>
    </div>

    <h4 class="tw-py-3 tw-font-bold">Players</h4>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <el-col :span="12" class="add-player">
        <el-form label-position="top" :model="add" :rules="teamListRules" ref="teamListRules">
          <el-form-item prop="_id">
            <el-select v-model="add._id" @change="setShirtNum" placeholder="Player" filterable>
              <el-option
                v-for="(item, index) in availablePlayersToAdd"
                :key="index"
                :label="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="position">
            <el-select v-model="addPosition" placeholder="Position" value-key="name" filterable>
              <el-option
                v-for="(item, index) in positions"
                :key="index"
                :label="item.name"
                :value="item.number"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="shirtNum">
            <el-input autocomplete="off" v-model="add.shirtNum" placeholder="Shirt #"></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="add.isCaptain"> Captain </el-checkbox>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12" class="add-player-col">
        <el-button type="success" @click="handleAddPlayer"> Add Player </el-button>
      </el-col>
    </div>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <data-tables
        v-if="teamlist.length > 0"
        :data="teamlist"
        :table-props="tableProps"
        :page-size="pageSize"
        @row-click="removeFromPlayerTeamList"
        id="teamlist"
        class="data-table"
      >
        <el-table-column fixed prop="_id" label="Id" width="100"> </el-table-column>
        <el-table-column prop="firstName" label="First Name"> </el-table-column>
        <el-table-column prop="lastName" label="Last Name"> </el-table-column>
        <el-table-column prop="position.name" label="Position"> </el-table-column>
        <el-table-column prop="shirtNum" label="Shirt #"> </el-table-column>
        <el-table-column align="center" prop="isCaptain" label="Captain">
          <template slot-scope="scope">
            <i v-if="scope.row.isCaptain" class="el-icon-success captain-icon"></i>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="Actions">
          <template>
            <i class="el-icon-delete remove-icon"></i>
          </template>
        </el-table-column>
      </data-tables>
    </div>
    <br />
    <h4 class="tw-py-3 tw-font-bold">Non-Players</h4>
    <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
      <div class="tw-flex-1" :span="8">
        <el-form
          class="tw-flex tw-gap-4"
          label-position="top"
          :model="addNP"
          :rules="npteamListRules"
          ref="npteamListRules"
        >
          <el-form-item prop="role">
            <el-select
              @change="clearNonplayer"
              v-model="addNP.role"
              placeholder="Role"
              value-key="name"
              clearable
              filterable
            >
              <el-option
                v-for="(item, index) in nonPlayerRoles"
                :key="index.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="_id">
            <el-select v-model="addNP._id" placeholder="Member" clearable filterable>
              <el-option
                v-for="(item, index) in availableNonPlayersToAdd"
                :key="index"
                :label="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="tw-flex-1">
        <button
          class="tw-py-2 tw-text-sm tw-font-medium tw-tracking-widest tw-text-white tw-uppercase tw-bg-green-700 tw-rounded hover:tw-bg-green-300 tw-px-7 hover:tw-text-black"
          type="success"
          @click="handleAddNonPlayer"
        >
          Add Non Player
        </button>
      </div>
    </div>
    <el-row>
      <data-tables
        v-if="nonplayerTeamList.length > 0"
        :data="nonplayerTeamList"
        :table-props="tableProps"
        :page-size="pageSize"
        @row-click="removeFromNonPlayerTeamList"
        id="np-teamlist"
        class="data-table"
      >
        <el-table-column fixed prop="_id" label="Id" width="100"> </el-table-column>
        <el-table-column prop="firstName" label="First Name"> </el-table-column>
        <el-table-column prop="lastName" label="Last Name"> </el-table-column>
        <el-table-column prop="role" label="Role">
          <template slot-scope="scope">
            {{ nonPlayerRoles.find((role) => role.type === scope.row.role).name }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="Actions">
          <template>
            <i class="el-icon-delete remove-icon"></i>
          </template>
        </el-table-column>
      </data-tables>
    </el-row>

    <el-row>
      <el-col style="width: 100%" :span="6" class="add-non-player"> </el-col>
      <el-col :span="12">
        <el-button class="long-btn" type="success" @click="submitTeamList">
          SUBMIT TEAM LIST
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { errormsg, nonPlayerRoles } from "../../utils/constants";

export default {
  name: "TeamList",
  props: {
    team: Object,
    teamid: Number,
    matchid: Number,
    isHomeTeam: Boolean,
    positions: Array,
    competitionid: Number,
  },
  data() {
    return {
      playersPrev: [],
      npPrev: [],
      playersDefault: [],
      npDefault: [],
      add: {
        _id: null,
        position: { number: null },
        shirtNum: null,
        isCaptain: false,
      },
      addNP: {
        _id: null,
        role: null,
      },
      teamListRules: {
        _id: { required: true, message: errormsg.select_option, trigger: "blur" },
        //  position: { required: true, message: errormsg.select_option, trigger: "blur" },
        // shirtNum: { required: true, message: errormsg.select_option, trigger: "blur" }
      },
      npteamListRules: {
        _id: { required: true, message: errormsg.select_option, trigger: "blur" },
        role: { required: true, message: errormsg.select_option, trigger: "blur" },
      },
      resubmit: false,
      tableProps: {
        border: true,
      },
      pageSize: 100,
      nonPlayerRoles,
    };
  },
  async created() {
    try {
      const { competitionid, teamid, matchid, isHomeTeam } = this;
      this.$store.commit("root/loading", true);
      try {
        await Promise.all([
          this.$http.get(
            `/nrl/api/v1/admin/teamlists/teams/${teamid}/competitions/${competitionid}/previous`
          ),
          this.$http.get(`/nrl/api/v1/admin/teams/${teamid}/defaultsquad`),
        ]).then(([matchesRes, defaultSquadRes]) => {
          if (
            matchesRes &&
            matchesRes.data &&
            matchesRes.data.data &&
            matchesRes.data.data[0] &&
            matchesRes.data.data[0].players &&
            matchesRes.data.data[0].nonplayers
          ) {
            this.playersPrev = matchesRes.data.data[0].players;
            this.npPrev = matchesRes.data.data[0].nonplayers;
          }

          if (
            defaultSquadRes &&
            defaultSquadRes.data &&
            defaultSquadRes.data.data &&
            defaultSquadRes.data.data.defaultSquad.players &&
            defaultSquadRes.data.data.defaultSquad.nonPlayers
          ) {
            this.playersDefault = defaultSquadRes.data.data.defaultSquad.players;
            this.npDefault = defaultSquadRes.data.data.defaultSquad.nonPlayers;
          }
        });
      } catch (e) {
        // all good. Typically a role permission issue
      }
      await Promise.all([
        this.$store.dispatch("match/getPlayers", { teamid, isHomeTeam }),
        this.$store.dispatch("match/getTeamList", { matchid, isHomeTeam }),
      ]);
      // .then(([matchesRes]) => {

      this.$store.commit("root/loading", false);
      //  })
    } catch (e) {
      this.$store.commit("root/loading", false);
      this.$customError();
    }
    // .catch(() => {
    //   this.$store.commit("root/loading", false);
    //   this.$customError();
    // });
  },
  methods: {
    clearNonplayer() {
      this.$set(this.addNP, "_id", null);
    },
    async submitPrevious() {
      // loop thru non players and add one by one
      // loop thru players and add one by one
      try {
        if (this.npPrev.length === 0 && this.playersPrev.length === 0) {
          this.$customError("Apologies, we could not find a previous team.");
          return;
        }
        for (const nonplayer of this.npPrev) {
          const found = this.nonplayerTeamList.find((tl) => tl._id === nonplayer._id);
          if (found) {
            continue;
          }
          this.$set(this.addNP, "role", nonplayer.role);
          this.$set(this.addNP, "_id", nonplayer._id);
          this.$set(this.addNP, "snap", nonplayer._id);
          await this.handleAddNonPlayer();
        }

        for (const player of this.playersPrev) {
          const found = this.teamlist.find((tl) => tl._id === player._id);
          if (found) {
            continue;
          }

          this.$set(this.add, "_id", player._id);
          this.$set(this.add, "position", player.position);
          this.$set(this.add, "shirtNum", player.shirtNum);
          this.$set(this.add, "isCaptain", player.isCaptain);

          await this.handleAddPlayer();
        }
        this.$customSuccess("Previous weeks team added! Submit the Team List to save.");
      } catch (e) {}
    },
    async submitDefault() {
      // loop thru non players and add one by one
      // loop thru players and add one by one
      try {
        if (this.npDefault.length === 0 && this.playersDefault.length === 0) {
          this.$customError("Apologies, we could not find a default squad.");
          return;
        }
        for (const nonplayer of this.npDefault) {
          const found = this.nonplayerTeamList.find((tl) => tl._id === nonplayer._id);

          if (found) {
            continue;
          }
          this.$set(this.addNP, "role", nonplayer.role);
          this.$set(this.addNP, "_id", nonplayer._id);
          this.$set(this.addNP, "snap", nonplayer._id);
          await this.handleAddNonPlayer();
        }

        const unavailablePlayers = [];
        for (const player of this.playersDefault) {
          const found = this.teamlist.find((tl) => tl._id === player._id);
          if (found) continue;

          const { _id } = player;

          let foundPlayer;
          if (this.isTfa) foundPlayer = this.availablep.find((p) => p._id === _id);
          else foundPlayer = this.availablep.find((p) => p._id === _id);

          if (foundPlayer && foundPlayer.available && foundPlayer.available.is === false) {
            this.$customError(`${foundPlayer.name} unable to be added due to unavailability.`);
            continue;
          }

          this.$set(this.add, "_id", player._id);
          this.$set(this.add, "position", player.position);
          this.$set(this.add, "shirtNum", player.shirtNum);
          this.$set(this.add, "isCaptain", player.isCaptain);

          await this.handleAddPlayer();
        }
        this.$customSuccess("Default squad added! Submit the Team List to save.");
      } catch (e) {
        console.log({ e });
      }
    },
    async handleAddPlayer() {
      try {
        await new Promise((resolve, reject) => {
          this.$refs.teamListRules.validate((valid) => (valid ? resolve() : reject()));
        });
        const { _id, position, shirtNum, isCaptain } = this.add;

        const player = this.availablep.find((p) => p._id === _id);

        const { available, profile } = player;
        const { firstName, middleName, lastName, isPrivate } = profile;

        if (available && available.is === false) {
          const error =
            available.notes && available.notes !== ""
              ? `${errormsg.player_unavailable} due to ${available.notes}`
              : errormsg.player_unavailable;
          this.$customError(error);
          throw new Error(error);
        }
        if (!position.name) {
          const positionDetails = this.positions.find((p) => p.number === position.number);
          this.$set(this.add, "position", positionDetails);
        }

        const found = this.teamlist.find((tl) => tl.position.number === position.number);
        if (found) {
          this.$customError(errormsg.same_position);
          throw new Error(errormsg.same_position);
        }

        this.teamlist.push({
          _id,
          shirtNum,
          firstName,
          middleName,
          lastName,
          preferredName:
            player.profile.meta && player.profile.meta.preferredName
              ? player.profile.meta.preferredName
              : "",
          isPrivate: isPrivate || false,
          position,
          isCaptain,
        });

        this.$set(this.add, "_id", null);
        this.$set(this.add, "position", { number: null });
        this.$set(this.add, "shirtNum", null);
        this.$set(this.add, "isCaptain", false);
      } catch (e) {
        this.$customError("Could not add Player");
        throw new Error("Could not add Player");
      }
    },
    async handleAddNonPlayer() {
      try {
        await new Promise((resolve, reject) => {
          this.$refs.npteamListRules.validate((valid) => (valid ? resolve() : reject()));
        });

        const { _id, role } = this.addNP;

        const player = this.availablenp.find((p) => p._id === _id);

        const { available, profile } = player;

        const { firstName, middleName, lastName, isPrivate } = profile;

        if (available && available.is === false) {
          const error =
            available.notes && available.notes !== ""
              ? `${errormsg.player_unavailable} due to ${available.notes}`
              : errormsg.player_unavailable;
          this.$customError(error);
          throw new Error(error);
        }

        this.nonplayerTeamList.push({
          _id,
          role,
          firstName,
          middleName,
          lastName,
          preferredName:
            player.profile.meta && player.profile.meta.preferredName
              ? player.profile.meta.preferredName
              : "",
          isPrivate: isPrivate || false,
        });

        this.$set(this.addNP, "_id", null);
        this.$set(this.addNP, "role", null);
      } catch (e) {
        this.$customError("Could not add Non-Player");
        throw new Error("Could not add Non-Player");
      }
    },
    removeFromPlayerTeamList(row) {
      this.$confirm(
        "Are you sure you want to remove this player from the team list",
        "Remove player",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          this.teamlist.splice(
            this.teamlist.findIndex((p) => p._id === row._id),
            1
          );
        })
        .catch(() => {});
    },
    removeFromNonPlayerTeamList(row) {
      this.$confirm(
        "Are you sure you want to remove this non-player from the team list",
        "Remove non-player",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          this.nonplayerTeamList.splice(
            this.nonplayerTeamList.findIndex((p) => p._id === row._id),
            1
          );
        })
        .catch(() => {});
    },
    setShirtNum(v) {
      const player = this.playersDefault.find((p) => p._id === v);
      if (player && player.shirtNum) this.add.shirtNum = player.shirtNum;
      else this.add.shirtNum = null;
    },
    submitTeamList() {
      this.$confirm(
        "Submitting this team list will override existing one. Do you want to continue?",
        "Submit Team List",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          this.$store.commit("root/loading", true);
          if (this.teamlistid) {
            this.$http
              .put(`/nrl/api/v1/admin/teamlists/${this.teamlistid}`, {
                players: this.teamlist,
                nonplayers: this.nonplayerTeamList,
              })
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch((e) => {
                if (e.response && e.response.data && e.response.data.message) {
                  this.$customError(e.response.data.message);
                } else {
                  this.$customError();
                }

                this.$store.commit("root/loading", false);
              });
          } else {
            this.$http
              .post("/nrl/api/v1/admin/teamlists/", {
                players: this.teamlist,
                nonplayers: this.nonplayerTeamList,
                matchid: this.matchid,
                isHomeTeam: this.isHomeTeam,
                team: this.team,
                status: "final",
              })
              .then(() => {
                this.$customSuccess();
                this.$store.commit("root/loading", false);
              })
              .catch((e) => {
                if (e.response && e.response.data && e.response.data.message) {
                  this.$customError(e.response.data.message);
                } else {
                  this.$customError();
                }
                this.$store.commit("root/loading", false);
              });
          }
        })
        .catch(() => {});
    },
  },
  computed: {
    currentNPType() {
      return this.addNP.role
        ? this.nonPlayerRoles.find((p) => p.type === this.addNP.role).memberType
        : null;
    },
    teamlistid() {
      return this.$store.getters["match/teamlistid"](this.isHomeTeam);
    },
    availablep() {
      return this.$store.getters["match/availablePlayers"](this.isHomeTeam);
    },
    availablenp() {
      return this.$store.getters["match/availableNonPlayers"](this.isHomeTeam);
    },
    teamlist: {
      get() {
        const { isHomeTeam } = this;
        const currentTeamList = this.$store.getters["match/teamlist"](isHomeTeam);
        return currentTeamList.sort((a, b) =>
          a.position.order > b.position.order ? 1 : a.position.order < b.position.order ? -1 : 0
        );
      },
      set(data) {
        const { isHomeTeam } = this;
        this.$store.commit("match/setTeamList", { isHomeTeam, data, type: "player" });
      },
    },
    nonplayerTeamList: {
      get() {
        const { isHomeTeam } = this;
        return this.$store.getters["match/npteamlist"](isHomeTeam);
      },
      set(data) {
        const { isHomeTeam } = this;
        this.$store.commit("match/setTeamList", { isHomeTeam, data, type: "nonplayer" });
      },
    },
    availablePlayersToAdd() {
      const available = this.availablep;
      const list = this.teamlist;
      return available ? available.filter((p) => !list.some((pl) => pl._id === p._id)) : [];
    },
    availableNonPlayersToAdd() {
      // For ease of management on the front-end, NP [activeRegos] are mapped to an array of roles without the key `memberType`
      const available = this.availablenp;
      const list = this.nonplayerTeamList;
      if (this.isTfa) {
        return available
          ? available
              .filter((p) => !list.some((pl) => pl._id === p._id))
              .filter((p) => (this.currentNPType ? p.activeRegos.memberType : false))
          : [];
      }
      return available
        ? available
            .filter((p) => !list.some((pl) => pl._id === p._id))
            .filter((p) =>
              this.currentNPType ? p.activeRegos.includes(this.currentNPType.toLowerCase()) : false
            )
        : [];
    },
    addPosition: {
      get() {
        return this.add.position.number;
      },
      set(value) {
        const position = this.positions.find((p) => p.number === value);
        if (position) {
          this.add.shirtNum = position.number.toString();
        }
        this.add.position = position;
      },
    },
    isTfa() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
  },
  watch: {
    // "addNP.role": function() {
    //   this.$set(this.addNP, "_id", null);
    // },
    teamid(teamid) {
      const { isHomeTeam } = this;
      this.$store
        .dispatch("match/getPlayers", { isHomeTeam, teamid })
        .then(() => {
          const playerTeamList = this.teamlist;
          const npTeamList = this.nonplayerTeamList;
          this.$store.commit("match/setTeamList", {
            isHomeTeam,
            data: playerTeamList.filter((player) =>
              this.availablePlayersToAdd.some((p) => p._id === player._id)
            ),
            type: "player",
          });
          this.$store.commit("match/setTeamList", {
            isHomeTeam,
            data: npTeamList.filter((player) =>
              this.availableNonPlayersToAdd.some((p) => p._id === player._id)
            ),
            type: "nonplayers",
          });
        })
        .catch(() => {
          this.$customError();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.data-table {
  .captain-icon {
    color: green;
    font-size: 2rem;
  }
  .remove-icon {
    color: red;
    font-size: 2rem;
  }
}

.add-player {
  .el-form {
    .el-form-item {
      max-width: 25%;
      float: left;
      padding-right: 1rem;
    }
  }
  margin-bottom: 0rem;
}
.long-btn {
  width: 16rem !important;
  height: 2.3rem;
}

.add-non-player {
  .el-form {
    .el-form-item {
      max-width: 100%;
      float: left;
      padding-right: 1rem;
    }
  }
  margin-bottom: 1.5rem;
}

.el-col-6 {
  text-align: right;
}
.el-button {
  width: 14rem;
}
</style>
