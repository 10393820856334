/* eslint-disable no-restricted-globals */
import { getField, updateField } from "vuex-map-fields";
import _ from "lodash";

import httpauth from "../axios/httpauth";
import moment from "moment-timezone";

const formatWithName = (pl) => ({ ...pl, name: `${pl.profile.firstName} ${pl.profile.lastName}` });
const getDefaultState = () => ({
  match: {
    assignedVoters: [],
    awardTeam: [],
    votingOpen: true,
    votesSubmitted: [],
    competition: {
      _id: null,
      name: null,
      awardVoters: null,
    },
    orgtree: {
      association: { _id: null, name: null },
    },
    round: {
      type: null,
      number: null,
      displayName: "",
    },
    venue: {
      _id: null,
      name: null,
      venueTimezone: null,
    },
    homeTeam: {
      _id: null,
      name: null,
    },
    awayTeam: {
      _id: null,
      name: null,
    },
    allowScoring: null,
    meta: {
      isTba: false,
      isBye: false,
      fieldNo: null,
      forfeitingTeam: {
        name: null,
        _id: null,
      },
      liveUrl: null,
      liveDateTime: null,
    },
    scores: {
      homeTeam: 0,
      awayTeam: 0,
    },
    scoringEvents: [],
    status: null,
    dateTime: null,
    matchDate: "",
    matchTime: "",
  },
  homeTeam: {
    teamlistid: null,
    availablePlayers: [],
    availableNonPlayers: [],
    teamlist: [],
    nonplayerTeamList: [],
    movements: [],
    scorings: [],
  },
  awayTeam: {
    teamlistid: null,
    availablePlayers: [],
    availableNonPlayers: [],
    teamlist: [],
    nonplayerTeamList: [],
    movements: [],
    scorings: [],
  },
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateField,
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setMatch(state, match) {
      state.match = _.merge({}, state.match, match);
    },
    setCompetition(state, value) {
      state.match.competition = value;
    },
    setAwardTeamId(state, id) {
      state.match.competition.awardVoters = id || null;
    },
    setAwardTeam(state, voters) {
      state.match.awardTeam = voters;
    },
    setVoteStatus(state, status) {
      state.match.votingOpen = status;
    },
    setVotedOn(state, arr) {
      state.match.votesSubmitted = arr;
    },
    setVoters(state, arr) {
      state.match.assignedVoters = arr;
    },
    setVenue(state, value) {
      state.match.venue = value;
    },
    setTeam(state, { type, data }) {
      state.match[type] = data;
    },
    setDateTime(state, value) {
      state.match.dateTime = value;
    },
    setMatchDate(state, value) {
      state.match.matchDate = value;
    },
    setMatchTime(state, value) {
      state.match.matchTime = value;
    },
    setForfeitingTeam(state, { name, _id }) {
      state.match.meta.forfeitingTeam = { name, _id };
    },
    setAvailablePlayers(state, { isHomeTeam, data, type }) {
      const team = isHomeTeam ? "homeTeam" : "awayTeam";
      const field = type === "player" ? "availablePlayers" : "availableNonPlayers";
      state[team][field] = data || [];
    },
    setTeamList(state, { isHomeTeam, data, type }) {
      const team = isHomeTeam ? "homeTeam" : "awayTeam";
      const field = type === "player" ? "teamlist" : "nonplayerTeamList";
      state[team][field] = data || [];
    },
    setTeamListId(state, { isHomeTeam, id }) {
      state[isHomeTeam ? "homeTeam" : "awayTeam"].teamlistid = id;
    },
    setMovement(state, { isHomeTeam, data }) {
      const team = isHomeTeam ? "homeTeam" : "awayTeam";
      state[team].movements = data
        .filter((event) => event.isHomeTeam === isHomeTeam)
        .sort((a, b) => {
          // Compare by 'min' first
          if (a.min !== b.min) {
            return b.min - a.min; // Descending order for 'min'
          }
          // If 'min' values are equal, compare by 'sec'
          return b.sec - a.sec; // Descending order for 'sec'
        });
    },
    setScoring(state, { isHomeTeam, data }) {
      const team = isHomeTeam ? "homeTeam" : "awayTeam";
      state[team].scorings = data
        .filter((event) => event.isHomeTeam === isHomeTeam)
        .map((event) => ({
          ...event,
          playerName: event.meta.player
            ? `${event.meta.player.firstName} ${event.meta.player.lastName}`
            : "",
        }));
      // state.scores[team] = state[team].scorings.reduce((total, score) => total + score.value, 0);
    },
    setOverrideHomeScore(state, value) {
      const homeScore = parseInt(value);
      state.match.scores.homeTeam = isNaN(homeScore) ? 0 : homeScore;
    },
    setOverrideAwayScore(state, value) {
      const awayScore = parseInt(value);
      state.match.scores.awayTeam = isNaN(awayScore) ? 0 : awayScore;
    },
    changeScore(state, { isHomeTeam, score, add }) {
      const team = isHomeTeam ? "homeTeam" : "awayTeam";
      if (add) {
        state.match.scores[team] += score;
      } else {
        state.match.scores[team] -= score;
      }
    },
    addScoringEvent(state, event) {
      state.match.scoringEvents.push(event);
    },
    removeScoringEvent(state, eventid) {
      const { scoringEvents } = state.match;
      const updated = scoringEvents.filter((e) => e._id !== eventid);
      state.match.scoringEvents = updated;
    },
    setFieldNo(state, number) {
      state.match.meta.fieldNo = number;
    },
  },
  actions: {
    getMatch({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        httpauth
          .get(`nrl/api/v1/admin/matches/${id}`)
          .then((response) => {
            commit("setMatch", response.data.data);
            const venueTz =
              state.match && state.match.venue && state.match.venue.venueTimezone
                ? state.match.venue.venueTimezone
                : moment.tz.guess();

            const date = moment.tz(state.match.dateTime, venueTz).format("YYYY-MM-DD");
            const time = moment.tz(state.match.dateTime, venueTz).format("HH:mm");
            commit("setMatchDate", date);
            commit("setMatchTime", time);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getVoteStatus({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        httpauth
          .get(`/nrl/api/v1/admin/matches/${id}/vote-status`)
          .then((response) => {
            const d = response.data.data;
            const { matchAssignments, uniqueVotingIds } = d;

            if (matchAssignments.length) {
              const sa = [];
              const newArr = matchAssignments.map((v) => {
                const { memberId, status } = v;
                const statusArr = status.split("_");
                const votingOpen = statusArr.includes("open");
                sa.push(votingOpen);
                return { memberId, votingOpen };
              });
              const unique = sa.filter((v, i, s) => {
                return s.indexOf(v) === i;
              });

              const voteStatus = unique.length === 1 ? unique[0] : undefined;
              commit("setVoteStatus", voteStatus);
              commit("setVotedOn", uniqueVotingIds);
              commit("setVoters", newArr);
              // res = d[0]._id
            }
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getTeamList({ commit }, { matchid, isHomeTeam }) {
      return new Promise((resolve, reject) => {
        httpauth
          .get(`/nrl/api/v1/admin/teamlists/match/${matchid}`)
          .then((res) => {
            const teamlist = res.data.data[isHomeTeam ? "home" : "away"];
            commit("setTeamListId", { isHomeTeam, id: teamlist._id });
            commit("setTeamList", { isHomeTeam, data: teamlist.players, type: "player" });
            commit("setTeamList", { isHomeTeam, data: teamlist.nonplayers, type: "nonplayer" });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPlayers({ commit }, { teamid, isHomeTeam }) {
      return new Promise((resolve, reject) => {
        Promise.all([
          httpauth.get(`/nrl/api/v1/admin/teamlists/players/${teamid}`),
          httpauth.get(`/nrl/api/v1/admin/teamlists/nonplayers/${teamid}`),
        ])
          .then(([playerRes, nonPlayerRes]) => {
            commit("setAvailablePlayers", {
              isHomeTeam,
              data: playerRes.data.data.map((p) => formatWithName(p)),
              type: "player",
            });
            commit("setAvailablePlayers", {
              isHomeTeam,
              data: nonPlayerRes.data.data.map((p) => formatWithName(p)),
              type: "nonplayer",
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getMovements({ commit }, { matchid, isHomeTeam }) {
      return new Promise((resolve, reject) => {
        httpauth
          .get(`/nrl/api/v1/admin/matches/movements/${matchid}`)
          .then((res) => {
            commit("setMovement", { isHomeTeam, data: res.data.data });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    getScorings({ commit }, { matchid, isHomeTeam }) {
      return new Promise((resolve, reject) => {
        httpauth
          .get(`/nrl/api/v1/admin/matches/scorings/${matchid}`)
          .then((res) => {
            commit("setScoring", { isHomeTeam, data: res.data.data });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    getVoters({ commit }, id) {
      return new Promise((resolve, reject) => {
        httpauth
          .get(`/nrl/api/v1/admin/teamlists/players/${id}`)
          .then((res) => {
            commit("setAwardTeam", res.data.data);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
  getters: {
    getField,
    match: (state) => state.match,
    awardTeam: (state) => state.match.awardTeam,
    competitionName: (state) => state.match.competition.name,
    awardVoters: (state) => state.match.competition.awardVoters,
    venueName: (state) => state.match.venue.name,
    homeTeamName: (state) => state.match.homeTeam.name,
    awayTeamName: (state) => state.match.awayTeam.name,
    matchTeams: (state) => [state.match.homeTeam.name, state.match.awayTeam.name],
    homeTeamId: (state) => state.match.homeTeam._id,
    awayTeamId: (state) => state.match.awayTeam._id,
    homeScore: (state) => state.match.scores.homeTeam,
    awayScore: (state) => state.match.scores.awayTeam,
    forfeitingTeamName: (state) =>
      state.match.meta.forfeitingTeam ? state.match.meta.forfeitingTeam.name : null,
    availablePlayers: (state) => (isHomeTeam) =>
      state[isHomeTeam ? "homeTeam" : "awayTeam"].availablePlayers,
    availableNonPlayers: (state) => (isHomeTeam) =>
      state[isHomeTeam ? "homeTeam" : "awayTeam"].availableNonPlayers,
    teamlist: (state) => (isHomeTeam) => state[isHomeTeam ? "homeTeam" : "awayTeam"].teamlist,
    npteamlist: (state) => (isHomeTeam) =>
      state[isHomeTeam ? "homeTeam" : "awayTeam"].nonplayerTeamList,
    teamlistid: (state) => (isHomeTeam) => state[isHomeTeam ? "homeTeam" : "awayTeam"].teamlistid,
    movements: (state) => (isHomeTeam) => state[isHomeTeam ? "homeTeam" : "awayTeam"].movements,
    scorings: (state) => (isHomeTeam) => state[isHomeTeam ? "homeTeam" : "awayTeam"].scorings,
    matchForSubmit: (state) => {
      const { match } = state;
      const update = _.cloneDeep(match);
      if (!update.round.displayName || update.round.displayName === "")
        update.round.displayName = `Round ${update.round.number}`;
      if (!update.homeTeam.name) update.homeTeam = null;
      if (!update.awayTeam.name) update.awayTeam = null;
      return update;
    },
  },
};
